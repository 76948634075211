import styles from './PrivatVideoChat.module.css';
import arrow from './img/arrowDown.svg';
import share from './img/share.svg';
import gift from './img/gift.svg';
import remove from './img/remove.svg';
import add from './img/add.svg';
import circle from './img/circle.svg';
import info from './img/info.svg';
import redArrow from './img/arrowRed.svg';
import {useEffect, useState, useRef} from 'react';
import PrivateStream from '../privateStream/PrivateStream';
import Chat from './chat/Chat';
import {get_chat_id} from '../../api/chats';
import {socket} from '../../socket/socket';
import {sortedData} from '../../utils/helpers';
import {friend_add, friend_del, user_friend_check} from '../../api/users';
import {ModalComplaining} from '../modalСomplaining/ModalComplaining';
import {ModalGift} from '../modalGift/ModalGift';
import {ShareModal} from './ShareModal';
import {ChatRulesBig} from '../chatRulesBig/ChatRulesBig';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css'

const INITIAL_MODAL_HEIGHT = 729
const INITIAL_MODAL_WIDTH = 450

const INITIAL_HIDE_MODAL_HEIGHT = 420
const INITIAL_HIDE_MODAL_WIDTH = 450


export const PrivatVideoChat = ({userMyId, isModal, videoChat, setOpenVideoPrivateChat, setOpenMainMenu, dragContainerPositions, privateClient, onUpdateClientInstance}) => {
    const [activeItem, setActiveItem] = useState(1);
    const [chatsItem, setChatItems] = useState([]);
    const [reciveScrol, setReciveScrol] = useState(true);
    const [upScroll, setUpScroll] = useState(false);
    const [privateChat, setPrivateChat] = useState([]);
    const [checkMessageStatus, setCheckMessageStatus] = useState({});
    const [checkMessagesStatus, setCheckMessagesStatus] = useState({});
    const [checkFriend, setCheckFriend] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pageLast, setPageLast] = useState(1);
    const [page, setPage] = useState(1);
    const [modalComplainingOpen, setModalComplainingOpen] = useState(false);
    const [modalGiftOpen, setModalGiftOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const remoteVideoRef = useRef(null);

    const toggleFullScreen = () => {
        if (remoteVideoRef.current) {
          if (remoteVideoRef.current.requestFullscreen) {
            remoteVideoRef.current.requestFullscreen();
          } else if (remoteVideoRef.current.mozRequestFullScreen) {
            remoteVideoRef.current.mozRequestFullScreen(); // Firefox
          } else if (remoteVideoRef.current.webkitRequestFullscreen) {
            remoteVideoRef.current.webkitRequestFullscreen(); // Chrome, Safari & Opera
          } else if (remoteVideoRef.current.msRequestFullscreen) {
            remoteVideoRef.current.msRequestFullscreen(); // IE/Edge
          }
        }
      };

    const getFriends = async () => {
        try {
            const {data} = await user_friend_check({userId: videoChat.participants.caller?.userId});
            setCheckFriend(data.data.status);
        } catch (error) {
            console.error('error tafe friends', error);
        }
    };

    const getMessageInfo = async (id, pageNum) => {
        const param = {
            id,
            page: pageNum ? pageNum : '1',
        };
        try {
            pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
            pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
            const {data} = await get_chat_id(param);
            const sorted = sortedData(data.data);
            setPrivateChat((prevPrivateChat) => [...sorted, ...prevPrivateChat]);
            setPageLast(data.pagination.lastPage);
            data.pagination.nextPage ? setPage(data.pagination.nextPage) : setPage(page + 1);
        } catch (error) {
            console.error('getMessageInfo err', error);
        }
    };

    useEffect(() => {
        videoChat && videoChat.participants && getFriends();
    }, [videoChat]);

    useEffect(() => {
        videoChat.videochat?.chat?.id && getMessageInfo(videoChat.videochat?.chat?.id, 1);
        videoChat.videochat?.chat?.id && setActiveItem(0);
    }, [videoChat.videochat?.chat?.id]);

    useEffect(() => {
        if (checkMessageStatus && privateChat) {
            const updatedPrivateChat = privateChat.map(item => {
                if (Number(item.id) === Number(checkMessageStatus.messsageId)) {
                    return {
                        ...item,
                        ...checkMessageStatus,
                    };
                }
                return item;
            });

            setPrivateChat(updatedPrivateChat);
        }
    }, [checkMessageStatus]);

    useEffect(() => {
        if (checkMessagesStatus && privateChat) {
            const updatedPrivateChats = privateChat.map(chat => {
                if (checkMessagesStatus.messages.includes(chat.id)) {
                    return {...chat, status: 3};
                }
                return chat;
            });
            setPrivateChat(updatedPrivateChats);
        }
    }, [checkMessagesStatus]);

    const handleClick = (num) => {
        setActiveItem(num);
        setHideContent(true);
    };

    const handleEndVideoCall = () => {
        if (videoChat.videochat?.id) {
            socket.emit('end', {videochatId: videoChat.videochat?.id});
        } else {
            const send = {
                videochatId: videoChat.videochat?.id ? videoChat.videochat?.id : null,
                participants: {
                    callerId: videoChat?.participants?.callerId,
                    receiverId: videoChat?.participants?.receiverId
                },
            };
            socket.emit('end', send);
        }
        setOpenVideoPrivateChat(false)
    };

    const addToFriend = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            await friend_add({userId: videoChat.participants.caller?.userId});
            setCheckFriend(2);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteFriend = async () => {
        if (isLoading) {
            return;
        }
        try {
            setIsLoading(true);
            await friend_del({userId: videoChat.participants.caller?.userId});
            setCheckFriend(0);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const isOpen = (val) => {
        val === 'gift' ? setModalGiftOpen(true) : setModalComplainingOpen(true);
    };

    const onClose = () => {
        setModalComplainingOpen(false);
        setModalGiftOpen(false);
    };

    const [hideContent, setHideContent] = useState(true);
    const [rulesVisible, setRulesVisible] = useState(false);
    const [videoSharing, setVideoSharing] = useState(true);
    const [mutedRemote, setMutedRemote] = useState(false);
    const audioSharing = () => {
        if (privateClient && mutedRemote) {
            privateClient.toggleAudio(true);
            setMutedRemote(false);
        } else {
            privateClient.toggleAudio(false);
            setMutedRemote(true);
        }
    };

    const screenSharing = () => {
        if (privateClient && !videoSharing) {
            privateClient.toggleVideo({type: 'screen', enable: true});
            setVideoSharing(true);
        } else {
            privateClient.toggleVideo({type: 'screen', enable: false});
            setVideoSharing(false);
        }
    };

    return (
        <ResizableBox 
            width={!hideContent ? INITIAL_HIDE_MODAL_WIDTH : INITIAL_MODAL_WIDTH} 
            height={!hideContent ? INITIAL_HIDE_MODAL_HEIGHT : INITIAL_MODAL_HEIGHT} 
            minConstraints={!hideContent ? [INITIAL_HIDE_MODAL_WIDTH, INITIAL_HIDE_MODAL_HEIGHT] : [INITIAL_MODAL_WIDTH, INITIAL_MODAL_HEIGHT]} 
            maxConstraints={!hideContent ? [INITIAL_HIDE_MODAL_WIDTH + dragContainerPositions.left, INITIAL_HIDE_MODAL_HEIGHT + dragContainerPositions.top] : [INITIAL_MODAL_WIDTH + dragContainerPositions.left, INITIAL_MODAL_HEIGHT + dragContainerPositions.top]} 
            lockAspectRatio
            className={styles.resizebleClass}
            resizeHandles={!hideContent ? [] : undefined}
        >
        <div className={styles.container} >
            <div className={`${styles.top} header`}>
                <p>Приватный видео чат</p>
                <img
                    onClick={() => setHideContent(!hideContent)}
                    style={{rotate: hideContent ? '0deg' : '180deg'}}
                    className={styles.img} src={arrow} alt='arrow'/>
            </div>
            <div className={styles.imgContent}>
                <div className={styles.videoWrap}>
                    <PrivateStream
                        fullScreen={fullScreen}
                        setFullScreen={setFullScreen}
                        isModal={isModal}
                        mutedRemote={mutedRemote}
                        videoSharing={videoSharing}
                        setOpenVideoPrivateChat={setOpenVideoPrivateChat}
                        setOpenMainMenu={setOpenMainMenu}
                        privateClient={privateClient}
                        onUpdateClientInstance={onUpdateClientInstance}
                        remoteVideoRef={remoteVideoRef}
                        toggleFullScreen={toggleFullScreen}
                    />
                </div>
                <div className={styles.imgBottomContent}>
                    <div className={styles.switcher}>
                        <div className={styles.switcherBG}>
                            <p onClick={() => handleClick(0)} className={activeItem === 0 ? styles.active : ''}>Чат</p>
                            <p onClick={() => handleClick(1)} className={activeItem === 1 ? styles.active : ''}>Меню</p>
                        </div>
                    </div>
                    <div className={styles.right}>
                        <div onClick={() => setModalVisible(!modalVisible)} className={styles.share}>
                            {modalVisible &&
                                <ShareModal
                                    screenSharing={screenSharing}
                                    videoSharing={videoSharing}
                                    list={[{text: 'Развернуть'}]}
                                    toggleFullScreen={toggleFullScreen}
                                />
                            }
                            <img src={share} alt='share'/>
                        </div>
                        <div onClick={() => isOpen('gift')} className={styles.gift}>
                            <img src={gift} alt=''/>Сделать подарок
                        </div>
                    </div>
                </div>
            </div>
            {hideContent &&
                (
                    <div className={styles.chatMainContainer}>
                        {activeItem === 0 && (
                            <Chat
                                chatsItem={chatsItem}
                                setChatItems={setChatItems}
                                setUpScroll={setUpScroll}
                                reciveScrol={reciveScrol}
                                setReciveScrol={setReciveScrol}
                                setPrivateChat={setPrivateChat}
                                chatId={videoChat.videochat?.chat?.id}
                                privateChat={privateChat}
                                userMyId={userMyId}
                                getMessageInfo={getMessageInfo}
                                pageLast={pageLast}
                                page={page}
                                setCheckMessageStatus={setCheckMessageStatus}
                                setCheckMessagesStatus={setCheckMessagesStatus}
                                upScroll={upScroll}
                                mutedRemote={mutedRemote}
                                setMutedRemote={setMutedRemote}
                                audioSharing={audioSharing}
                            />
                        )}

                        {activeItem === 1 && (
                            <div className={styles.btnList}>
                                {checkFriend === 1 && (
                                    <div onClick={deleteFriend} className={styles.btn}>
                                        Удалить из друзей <img src={remove} alt=''/>
                                    </div>
                                )}
                                {checkFriend === 0 && (
                                    <div onClick={addToFriend} className={styles.btn}>
                                        Добавить в друзья <img src={add} alt=''/>
                                    </div>
                                )}
                                {checkFriend === 2 && (
                                    <div onClick={addToFriend} className={styles.btn}>
                                        Ожидает подтверждение <img src={remove} alt=''/>
                                    </div>
                                )}
                                <div onClick={() => isOpen()} className={styles.btn}>
                                    Пожаловаться <img src={circle} alt=''/>
                                </div>
                                <div onClick={() => setRulesVisible(!rulesVisible)} className={styles.btn}>
                                    Правила чата <img src={info} alt=''/>
                                </div>
                                <div onClick={handleEndVideoCall} className={styles.btn}>
                                    Завершить видео чат <img src={redArrow} alt=''/>
                                </div>
                            </div>
                        )}
                    </div>
                )
            }
            {modalComplainingOpen &&
                <ModalComplaining
                    userInfoData={videoChat?.videochat?.participants.find(user => user.id === videoChat.participants.caller.userId)}
                    name={videoChat?.videochat?.participants.find(user => user.id === videoChat?.participants?.caller?.userId)?.username}
                    isOpen={isOpen}
                    onClose={onClose}
                />}
            {modalGiftOpen &&
                <ModalGift
                    userInfoData={videoChat?.videochat?.participants.find(user => user.id === videoChat?.participants?.caller?.userId)?.id}
                    name={videoChat?.videochat?.participants.find(user => user.id === videoChat?.participants?.caller?.userId)?.username}
                    isOpen={isOpen}
                    onClose={onClose}
                />}

            <div
                style={{display: rulesVisible ? 'block' : 'none'}}
                className={rulesVisible ? styles.visibleRules : styles.notVisibleRules}
            >
                <ChatRulesBig
                    setRulesVisible={setRulesVisible}
                    rulesVisible={rulesVisible}
                    hideContent={hideContent}
                    setHideContent={setHideContent}
                />
            </div>

        </div>
        </ResizableBox>
    );
};