import s from '../ChatPublic.module.css';
import {SubMenu} from '../../subMenu/SubMenu';
import crystalImg from '../../../images/crystal.svg';
import burgerMenu from '../../../images/burgerMenu.svg';
import {isLink} from '../../../utils/helpers';
import {ModalComplaining} from '../../modalСomplaining/ModalComplaining';
import {useEffect, useRef, useState} from 'react';

export const ChatItem = ({
                             senderId,
                             senderUserName,
                             text,
                             sender,
                             setOpenChatById,
                             firstElem,
                             avatar
                         }) => {
    const [isOpenMini, setIsOpenMini] = useState(false);
    const [modalComplainingOpen, setModalComplainingOpen] = useState(false);
    const [userAdmin, setUserAdmin] = useState(false);

    const onClose = () => {
        setModalComplainingOpen(!modalComplainingOpen);
    };
    const submenuRef = useRef(null);
    const handleClickOutside = (event) => {
        if (submenuRef.current && !submenuRef.current.contains(event.target)) {
            setIsOpenMini(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className={`${s.items}`}>
            <div className={s.item}>
                {isOpenMini && (
                    <SubMenu
                        setOpenChatById={setOpenChatById}
                        setUserAdmin={setUserAdmin}
                        userAdmin={userAdmin}
                        setIsOpenMiniMenu={setIsOpenMini}
                        isPublic
                        userId={senderId}
                        userName={senderUserName}
                        setModalComplainingOpen={setModalComplainingOpen}
                        submenuRef={submenuRef}
                        firstElem={firstElem}
                    />
                )}
                <div className={s.headerChat}>
                    <div className={s.userName}>
                        <img
                            style={{width: '19px', height: '19px', borderRadius: '10px'}}
                            src={avatar || crystalImg}
                            alt='crystalImg'/>
                        <div>{senderUserName}</div>
                    </div>
                    <div
                        className={s.miniMenu}
                        onClick={() => setIsOpenMini(!isOpenMini)}
                    >
                        <img src={burgerMenu} alt='burgerMenu'/>
                    </div>
                </div>
                <div className={s.adaptTextBlock}>
                    {isLink(text) ? <a href={text}>{text}</a> : text}
                </div>
                {modalComplainingOpen && (
                    <ModalComplaining
                        userInfoData={sender}
                        name={senderUserName}
                        onClose={onClose}
                        op
                    />
                )}
            </div>
        </div>
    );
};
