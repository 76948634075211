export { socketPromise, socket } from '../../socket/socket';

export type TypeRecord = 'camera' | 'screen';
export const isMobile = /Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(
  navigator.userAgent,
);

export const ICE_SERVERS = [
  // { urls: 'stun:23.88.39.38:3478' },
  { urls: 'stun:stun.nextcloud.com:443' },
  { urls: 'stun:relay.webwormhole.io' },
  { urls: 'stun:stun.l.google.com:19302' },
  { urls: 'stun:stun1.l.google.com:19302' },
  { urls: 'stun:stun2.l.google.com:19302' },
  // { urls: 'stun:stun3.l.google.com:19302' },
  // { urls: 'stun:stun4.l.google.com:19302' },
];

export const genMediaStream = async (
  type: TypeRecord,
  audio: boolean = true,
): Promise<MediaStream> => {
  if (isMobile) type = 'camera';

  if (type == 'camera')
    return navigator.mediaDevices.getUserMedia({
      audio,
      video: true,
    });

  const videoStream = await navigator.mediaDevices.getDisplayMedia({
    video: true,
  });

  if (audio) {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    for (const track of audioStream.getAudioTracks())
      videoStream.addTrack(track);
  }

  return videoStream;
};

export const getGuid = () => {
  let S4 = () =>
    (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

  // prettier-ignore
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
};

export const customConfirm = async (q: string): Promise<boolean> =>
  new Promise((resolve) => {
    const genDiv = document.createElement('div');
    genDiv.classList.add('custom-confirm');

    const dialogDiv = document.createElement('div');
    dialogDiv.classList.add('dialog');
    genDiv.appendChild(dialogDiv);

    const qDiv = document.createElement('div');
    qDiv.innerText = q;
    dialogDiv.appendChild(qDiv);

    const btnCancel = document.createElement('button');
    btnCancel.innerText = 'Cancel';
    btnCancel.onclick = () => {
      document.body.removeChild(genDiv);
      resolve(false);
    };
    dialogDiv.appendChild(btnCancel);

    const btnSub = document.createElement('button');
    btnSub.innerText = 'OK';
    btnSub.onclick = () => {
      document.body.removeChild(genDiv);
      resolve(true);
    };
    dialogDiv.appendChild(btnSub);

    document.body.appendChild(genDiv);
  });
