import React from 'react'
import styles from "./styles.module.css";
import logo from "../../../images/logo_2.svg"
import arrow from '../imgs/arrow.svg'



const BlockNotMoney = ({ setIsNotMoney }) => {
  return (
    <div className={styles.containerNotMoney}>
        <div className={styles.logoTextBlock}>
               <img src={logo} alt='logo' />
               <div>На вашем балансе недостаточно Амуров для приватных бесед</div>
        </div>
        <div className={styles.button}>
                Пополнить баланс
        </div>
        <img className={styles.imgClose} onClick={() => setIsNotMoney(true)} style={{ cursor: 'pointer' }} src={arrow} alt='arrow' />
    </div>
  )
}

export default BlockNotMoney