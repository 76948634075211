import React, { useEffect, useRef, useState } from 'react';
import s from './ChatPrivate.module.css';
import {
  createUniqueArray,
  formatTime,
  groupMessagesByDay,
  isLink,
} from '../../../utils/helpers';
import MyIcon from '../../myIcon/MyIcon';
import avaImg from '../../../images/ava.svg';
import { post_invite_request } from '../../../api/video';
import { user_friend_check, user_other } from '../../../api/users';
import ModalUserProfileInfo from '../../modalProfile/ModalUserProfileInfo';
import { SubMenu } from '../../subMenu/SubMenu';
import { ModalComplaining } from '../../modalСomplaining/ModalComplaining';
import { useToast } from 'rc-toastr';
import { socket } from '../../../socket/socket';

const MAX_CHARACTER_LIMIT = 160;

const ChatPrivate = ({
  getMessageInfo,
  setPrivateChat,
  privateChat,
  chatId,
  pageLast,
  checkRequest,
  page,
  setPrivateChatRoute,
  setCheckMessageStatus,
  setCheckMessagesStatus,
  setChatId,
  setReciveScrol,
  upScroll,
  setIsHeader,
  setUpScroll,
  reciveScrol,
  userOtherId,
  hostesListStatus,
  getHostesListStatus,
  setCheckIsReadMyMessage,
  getChats,
}) => {
  const containerRef = useRef(null);
  const { toast } = useToast();
  const [messageInput, setMessageInput] = useState('');
  const [checkMessage, setCheckMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [status, setStatus] = useState(false);
  const [isOpenMiniMenu, setIsOpenMiniMenu] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [isProfileInfo, setIsProfileInfo] = useState(false);
  const [userInterests, setUserInterests] = useState([]);
  const [modalComplainingOpen, setModalComplainingOpen] = useState(false);
  const [isFriend, setIsFriend] = useState(null);

  useEffect(() => {
    const onReceiveMessage = (message) => {
      if (Number(chatId) === Number(message.chat.id)) {
        message.sender.id === userOtherId &&
          socket.emit('read_message', {
            messageId: message.id,
            chatId,
          });

        const res = {
          id: message.id,
          text: message.text,
          status: message.status,
          createdAt: message.createdAt,
          updatedAt: message.updatedAt,
          sender: message.sender,
        };
        setPrivateChat((prev) => [...prev, res]);
      }
    };

    const onMessageReaded = (message) => {
      setCheckIsReadMyMessage(true);
      setCheckMessageStatus(message);
    };

    const onMessagesReaded = (message) => {
      setCheckIsReadMyMessage(true);
      setCheckMessagesStatus(message);
    };

    if (chatId) {
      socket.emit('join_room', chatId);

      socket.on('receive_message', onReceiveMessage);
      socket.on('message_readed', onMessageReaded);
      socket.on('messages_readed', onMessagesReaded);
    }

    return () => {
      socket.off('receive_message', onReceiveMessage);
      socket.off('message_readed', onMessageReaded);
      socket.off('messages_readed', onMessagesReaded);
    };
  }, [chatId, containerRef.current]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, pageLast, privateChat]);

  useEffect(() => {
    if (reciveScrol && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (upScroll && containerRef.current) {
      containerRef.current.scrollTop = 443;
    }
  }, [reciveScrol, upScroll, privateChat]);

  useEffect(() => {
    if (checkMessage && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [checkMessage]);

  useEffect(() => {
    if (messageInput !== '') {
      setCheckMessage(false);
      setUpScroll(false);
      setReciveScrol(false);
    }
  }, [messageInput]);

  useEffect(() => {
    userOtherId && requestUserData();
  }, [userOtherId]);

  useEffect(() => {
    getCheckFriends();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast && !checkRequest) {
      if (container && container.scrollTop === 0) {
        setCheckMessage(false);
        timer = setTimeout(() => {
          getMessageInfo(chatId, page);
        }, 300);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    setCheckMessage(true);
    setReciveScrol(true);
    if (messageInput.trim() !== '') {
      const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');

      socket.emit('send_message', {
        chatId,
        message: cleanedMessageInput,
      });

      setCheckIsReadMyMessage(false);
      setMessageInput('');
    }
    setCharacterCount(0);
  };

  const requestUserData = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await user_other(userOtherId);
      setUserInfo(data.data);
      setUserInterests(JSON.parse(data.data.interests));
      hostesListStatus.includes(data.data.id) && setStatus(true);
    } catch (error) {
      console.error('requestUserData error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const inviteToVideo = async (id) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await post_invite_request({ userId: id });
      setStatus(true);
      getHostesListStatus();
    } catch (error) {
      console.error('inviteToVideo error', error);
      error.response.data.message === 'Not enough money to invite'
        ? toast.error('Не достаточно денег!')
        : toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getCheckFriends = async () => {
    try {
      setIsLoading(true);
      const { data } = await user_friend_check({ userId: userOtherId });
      setIsFriend(data.data.status);
    } catch (error) {
      console.error('error tafe friends', error);
    } finally {
      setIsLoading(false);
    }
  };

  const clickBack = () => {
    setChatId(null);
    setPrivateChatRoute(false);
    setIsHeader(true);
    setCheckMessage(true);
    setReciveScrol(true);
    setUpScroll(false);
    getChats();

    if (+chatId) socket.emit('leave_room', chatId);
  };

  const openProfileInfo = () => {
    setIsProfileInfo(true);
    setCheckMessage(false);
    setReciveScrol(false);
  };

  const closeProfileInfo = () => {
    setIsProfileInfo(false);
    setCheckMessage(true);
    setReciveScrol(true);
    setUpScroll(false);
  };

  const isOpen = () => {
    setModalComplainingOpen(true);
  };

  const onClose = () => {
    setModalComplainingOpen(false);
  };

  const groupedMessages = groupMessagesByDay(
    createUniqueArray(privateChat, 'id'),
  );
  const submenuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setIsOpenMiniMenu(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return isProfileInfo ? (
    <div className={s.profile}>
      <ModalUserProfileInfo
        userInfoData={userInfo}
        userInterestsData={userInterests}
        isOpen={openProfileInfo}
        statusInfo={status}
        setStatusInfo={setStatus}
        onClose={closeProfileInfo}
        getHostesListStatus={getHostesListStatus}
      />
    </div>
  ) : (
    userInfo && userInfo.username && !isLoading && (
      <div className={`${s.slideIn}`}>
        <div className={s.headerContainer}>
          <div className={s.header}>
            <div className={s.headerLeft}>
              <div style={{ cursor: 'pointer' }} onClick={clickBack}>
                <MyIcon image={'back'} width={'19px'} height={'19px'} />
              </div>
              <div onClick={openProfileInfo} className={s.headerText}>
                <img
                  src={userInfo.avatar ? userInfo.avatar : avaImg}
                  alt="avatar"
                />
                <div
                  className={`${userInfo.online ? s.imgOnline : s.imgOffline} ${
                    s.imgStatus
                  }`}
                ></div>
                <div>{userInfo.username}</div>
              </div>
            </div>
            <div className={s.headerRight}>
              <div className={s.headerRates}>
                {userInfo.rating && userInfo.rating > 3 ? (
                  <MyIcon image={'starFill'} width={'15px'} height={'15px'} />
                ) : (
                  <MyIcon image={'star'} width={'15px'} height={'15px'} />
                )}
                <div>{userInfo.rating}</div>
              </div>
              <div className={s.miniMenu}>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsOpenMiniMenu(!isOpenMiniMenu)}
                >
                  {isOpenMiniMenu ? (
                    <MyIcon
                      image={'miniMenuOpen'}
                      width={'21px'}
                      height={'21px'}
                    />
                  ) : (
                    <MyIcon image={'miniMenu'} width={'21px'} height={'21px'} />
                  )}
                </div>
                <div className={s.miniMenuBlock}>
                  {isOpenMiniMenu && (
                    <SubMenu
                      isPrivate
                      setIsFriend={setIsFriend}
                      isFriend={isFriend}
                      setModalComplainingOpen={setModalComplainingOpen}
                      setIsOpenModal={setIsOpenMiniMenu}
                      setIsOpenMiniMenu={setIsOpenMiniMenu}
                      openProfileInfo={openProfileInfo}
                      modalComplainingOpen={modalComplainingOpen}
                      userId={userInfo.id}
                      modalStyles
                      submenuRef={submenuRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {!userInfo?.gamerId && userInfo?.busy !== 0 && (
            <div className={`${s.closeToVideo}`}>
              Хостес сейчас занята, попробуйте позже
            </div>
          )}

          {!userInfo?.gamerId && userInfo?.busy === 0 && (
            <div
              className={`${status ? s.closeToVideo : s.entryToVideo}`}
              onClick={() => inviteToVideo(userInfo.id)}
            >
              {status
                ? 'Приглашение отправлено'
                : 'Пригласить в приватный видео чат'}
            </div>
          )}
        </div>

        <div ref={containerRef} className={s.container}>
          {Object.keys(groupedMessages).map((dayOfWeek) => (
            <div className={s.wrapper} key={dayOfWeek}>
              <div className={s.dayOfWeek}>
                <div>{dayOfWeek}</div>
              </div>
              {groupMessagesByDay(createUniqueArray(privateChat, 'id'))[
                dayOfWeek
              ].map((item, index) => (
                <div className={`${s.message}`} key={index}>
                  <div
                    className={`${
                      userOtherId !== item.sender?.id
                        ? s.rightContainer
                        : s.leftContainer
                    }`}
                  >
                    <div
                      className={`${
                        userOtherId !== item.sender?.id ? s.right : s.left
                      }`}
                    >
                      <div className={s.adaptTextBlock}>
                        {isLink(item.text) ? (
                          <a href={item.text}>{item.text}</a>
                        ) : (
                          item.text
                        )}
                      </div>
                      <div
                        className={`${
                          userOtherId !== item.sender?.id
                            ? s.rightStatusData
                            : s.leftStatusData
                        }`}
                      >
                        <div className={s.icon}>
                          {item.status === 3 && (
                            <MyIcon
                              width={'20px'}
                              height={'20px'}
                              image="readMessage"
                            />
                          )}
                          {item.status === 2 && (
                            <MyIcon
                              width={'20px'}
                              height={'15px'}
                              image="sendMessage"
                            />
                          )}
                        </div>
                        <div>{formatTime(item.createdAt)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className={s.footer}>
          {isFriend === 1 ? (
            <div className={s.messageBlock}>
              <div className={s.messageInput}>
                <input
                  type="text"
                  placeholder="Введите сообщение"
                  value={messageInput}
                  onChange={(e) => {
                    if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                      setMessageInput(e.target.value);
                      setCharacterCount(e.target.value.length);
                    }
                  }}
                  onKeyDown={handleKeyDown}
                />
                <div className={s.send}>
                  <div>{`${characterCount} / ${MAX_CHARACTER_LIMIT}`}</div>
                  <div onClick={sendMessage}>
                    <MyIcon
                      image={'sendMessageToUser'}
                      width={'20px'}
                      height={'20px'}
                    />
                  </div>
                </div>
              </div>
              <div className={s.line}></div>
            </div>
          ) : (
            <div className={s.notFriends}>
              Вы не можете отправить сообщение этому пользователю, посколько не
              являетесь друзьями
            </div>
          )}
        </div>
        {modalComplainingOpen && (
          <ModalComplaining
            userInfoData={userInfo}
            name={userInfo.username}
            isOpen={isOpen}
            onClose={onClose}
          />
        )}
      </div>
    )
  );
};

export default ChatPrivate;
