import './App.css';
import { Home } from './pages';
import { VideoChat } from './components/videoChat/videoChat';
import React, { useEffect, useState } from 'react';

const testToken =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImdhbWVyMUBnbWFpbC5jb20iLCJpYXQiOjE3MDM1ODU1MTl9.un9mh4I1z1O7i4JpFuJAdl2_5kknnaabd8AhVqdcPls';
const testToken2 =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InZ2YWQ4NDRAZ21haWwuY29tIiwiaWF0IjoxNzA4MzU2MjI2fQ.u1H6HjRlu0LIg-XnUswu-dMW7YL4sWTAkzOhQ42riRM';

function App() {
  const [token, setToken] = useState(testToken);

  useEffect(() => {
    setToken(testToken);
  }, []);

  return (
    <>
      <Home tokenProp={token} />
      <VideoChat tokenProp={token} />
    </>
  );
}

export default App;
