import styles from './MainMenu.module.css';
import { Searcher } from '../searcher/Searcher';
import arrow from './imgs/arrow.svg';
import { StreamerItem } from './components/StreamerItem';
import { useEffect, useRef, useState } from 'react';
import {
  get_users_hostes_list,
  user_balancy,
  user_friends_count,
  user_friends_request,
  user_info_status,
} from '../../api/users';
import ChatTab from '../chatTab/ChatTab';
import { chat_filter_and_search, get_chat_list_friends } from '../../api/chats';
import ModalUserProfileInfo from '../modalProfile/ModalUserProfileInfo';
import { chat_filter_and_search_hostes } from '../../api/filter';
import BlockNotMoney from './components/BlockNotMoney';
import { NewFriends } from '../newFriends/NewFriends';
import { FriendRequests } from '../friendRequests/FriendRequests';
import MyIcon from '../myIcon/MyIcon';
import { socket } from '../../socket/socket';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css'

const INITIAL_MODAL_HEIGHT = 620
const INITIAL_MODAL_WIDTH = 400

const MainMenu = ({ setOpenMainMenu, openChatById, getChtsCount, dragContainerPositions }) => {
  const containerRef = useRef(null);
  const [hostesList, setHostesList] = useState([]);
  const [chatsItem, setChatItems] = useState([]);
  const [hostesListStatus, setHostesListStatus] = useState([]);
  const [isProfileInfo, setIsProfileInfo] = useState(false);
  const [isNotMoney, setIsNotMoney] = useState(false);
  const [openFriendRequest, setOpenFriendRequest] = useState(false);
  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);
  const [isActive, setIsActive] = useState(0);
  const [isHeader, setIsHeader] = useState(true);
  const [userOtherId, setUserOtherId] = useState(null);
  const [userFriendCount, setUserFriendCount] = useState(null);
  const [userFriendRequest, setUserFriendRequest] = useState([]);
  const [countUnreadMessage, setCountUnreadMessage] = useState([]);
  const [balancy, setBalancy] = useState([]);
  const [mainContainerWidth, setMainContainerWidth] = useState(400)
  const [mainContainerHeight, setMainContainerHeight] = useState(620)

  const openProfileInfo = () => {
    setIsProfileInfo(true);
  };

  const closeProfileInfo = () => {
    setIsProfileInfo(false);
  };

  const filterRequest = async (username) => {
    try {
      if (isActive === 0) {
        const { data } = await chat_filter_and_search_hostes({ username });
        setHostesList(data.data);
      }
      if (isActive === 1) {
        const { data } = await chat_filter_and_search({ username });
        setChatItems(data.data);
      }
    } catch (error) {
      console.error('filterRequest', error);
      setHostesList([]);
      setChatItems([]);
    }
  };

  const getChats = async () => {
    try {
      const { data } = await get_chat_list_friends();
      setChatItems(data.data);
      const updatedCountUnreadMessage = data.data.map((chat) => ({
        chat_id: chat.chat_id,
        unreadMessageCount: chat.unreadMessageCount,
      }));
      setCountUnreadMessage(updatedCountUnreadMessage);
    } catch (error) {
      console.error('getChats error');
    }
  };

  const getBalancy = async () => {
    try {
      const { data } = await user_balancy();
      setBalancy(data.data);
    } catch (error) {
      console.error('getChats error');
    }
  };

  const getHostesList = async () => {
    try {
      const { data } = await get_users_hostes_list(page);
      const newTransactions = data.data;
      setHostesList((prevTransactions) => [
        ...prevTransactions,
        ...newTransactions,
      ]);
      setPage((prevPage) => prevPage + 1);
      setPageLast(data.pagination.lastPage);
    } catch (error) {
      console.error('getHostesList error');
    }
  };

  const getFriendCount = async () => {
    try {
      const { data } = await user_friends_count();
      setUserFriendCount(data.data.count);
    } catch (error) {
      console.error('getFriendCounnt error');
    }
  };

  const getFriendRequest = async () => {
    try {
      const { data } = await user_friends_request();
      setUserFriendRequest(data.data);
    } catch (error) {
      console.error('getFriendCounnt error');
    }
  };

  const getHostesListStatus = async () => {
    try {
      const { data } = await user_info_status();
      setHostesListStatus(data.data);
    } catch (error) {
      console.error('getHostesList error', error);
    }
  };

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
      if (
        container &&
        container.scrollTop + container.clientHeight >= container.scrollHeight
      ) {
        timer = setTimeout(() => {
          getHostesList();
        }, 300);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  useEffect(() => {
    getHostesList();
    getHostesListStatus();
  }, [isActive]);

  useEffect(() => {
    getChats();
    getBalancy();
    getFriendCount();
    getFriendRequest();
  }, []);

  useEffect(() => {
    openChatById && openChatById.chatId && setIsActive(1);
  }, [openChatById]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, pageLast, hostesList, hostesListStatus]);

  useEffect(() => {
    const onChangeOnline = ({
      id,
      online,
      about_me,
      avatar,
      avgRating,
      username,
    }) => {
      let isUserIdFound = false;
      const updatedHostesList = hostesList.map((item) => {
        if (Number(item.id) === id) {
          isUserIdFound = true;
          return { ...item, online };
        }
        return item;
      });

      if (!isUserIdFound && online === 1) {
        updatedHostesList.push({
          id,
          online: 1,
          about_me,
          avatar,
          avgRating,
          username,
        });
      }

      updatedHostesList.sort((a, b) => b.online - a.online);
      setHostesList(updatedHostesList);
    };

    socket.on('changeOnline', onChangeOnline);

    return () => {
      socket.off('newProducer', onChangeOnline);
    };
  }, [hostesList]);

  const onResize = (event, {size}) => {
    setMainContainerHeight(size.height)
    setMainContainerWidth(size.width)
  }

  return (
    <ResizableBox 
      width={INITIAL_MODAL_WIDTH} 
      height={INITIAL_MODAL_HEIGHT} 
      minConstraints={[INITIAL_MODAL_WIDTH, INITIAL_MODAL_HEIGHT]} 
      maxConstraints={[INITIAL_MODAL_WIDTH + dragContainerPositions.left, INITIAL_MODAL_HEIGHT + dragContainerPositions.top]} 
      onResize={onResize} 
      lockAspectRatio
    >
    <div className={styles.privatWrapper}>
      {isProfileInfo ? (
        <div className={styles.profile}>
          <ModalUserProfileInfo
            userData={userOtherId}
            isOpen={openProfileInfo}
            hostesListStatus={hostesListStatus}
            onClose={closeProfileInfo}
            getHostesListStatus={getHostesListStatus}
          />
        </div>
      ) : openFriendRequest ? (
        <div>
          <div className={styles.header}>
            <div
              onClick={() => setOpenFriendRequest(false)}
              className={styles.headerBack}
            >
              <MyIcon image={'back'} width={'20px'} height={'20px'} />
              Заявки в друзья
            </div>
            <img
              onClick={() => setOpenMainMenu(false)}
              style={{ cursor: 'pointer' }}
              src={arrow}
              alt="arrow"
            />
          </div>

          {userFriendRequest.map((item, i) => {
            console.log(item);
            return (
              <FriendRequests
                name={item?.username}
                id={item?.userId}
                isOnline={item?.online}
                getFriendRequest={getFriendRequest}
                getFriendCount={getFriendCount}
                img={item?.avatar}
                action={item}
                key={i}
              />
            );
          })}
        </div>
      ) : (
        <>
          {isHeader && (
            <Searcher
              setOpenMainMenu={setOpenMainMenu}
              filterRequest={filterRequest}
              setIsActive={setIsActive}
              isActive={isActive}
              getHostesListStatus={getHostesListStatus}
              title={'Приватные стримы и чаты'}
            />
          )}
          {userFriendCount !== 0 && isActive === 0 && (
            <NewFriends
              setOpenFriendRequest={setOpenFriendRequest}
              count={userFriendCount}
            />
          )}
          {isActive === 0 ? (
            <div
              ref={containerRef}
              className={
                userFriendCount ? styles.bottomWithFriendRequest : styles.bottom
              }
            >
              {hostesList.map((item, i) => (
                <StreamerItem
                  openProfileInfo={openProfileInfo}
                  setUserOtherId={setUserOtherId}
                  status={hostesListStatus.includes(item.id)}
                  hostesListStatus={hostesListStatus}
                  item={item}
                  key={i}
                />
              ))}
            </div>
          ) : (
            <div className={styles.chatTabBottom}>
              <ChatTab
                openChatById={openChatById.chatId}
                chatsItem={chatsItem}
                setIsHeader={setIsHeader}
                hostesListStatus={hostesListStatus}
                countUnreadMessage={countUnreadMessage}
                setCountUnreadMessage={setCountUnreadMessage}
                setChatItems={setChatItems}
                getChats={getChats}
                getHostesListStatus={getHostesListStatus}
                getChtsCount={getChtsCount}
              />
            </div>
          )}

          {balancy && balancy?.hearts < 2 && !isNotMoney && (
            <BlockNotMoney setIsNotMoney={setIsNotMoney} />
          )}
        </>
      )}
    </div>
    </ResizableBox>
  );
};

export default MainMenu;
