import styles from "./styles.module.css";
import star from "../imgs/star.svg";
import { truncateText } from "../../../utils/helpers";
import { Button } from "../../button/Button.jsx";
import avatarImg from "../../../images/ava.svg"
import { post_invite_request } from "../../../api/video";
import { useEffect, useState } from "react";
import { useToast } from "rc-toastr";

export const StreamerItem = (props) => {
  const { avatar, userAvatar, username, about_me, avgRating, online, id } =
    props.item;
  const { toast } = useToast()
  const [status, setStatus] = useState(props.status);
  const [isLoading, setIsLoading] = useState(false)
  const [token,setToken] = useState('')
  useEffect(() => {
    setToken(localStorage.getItem('gamer_info'))
  }, []);
  useEffect(()=>{
      console.log(token)
  },[token])
  useEffect(() => {
    setStatus(props.status);
  }, [props.status]);

  const inviteToVideo = async (id) => {
    if (isLoading) {
      return
    }

    try {
      setIsLoading(true)
      await post_invite_request({ userId: id });
      setStatus(true);
    } catch (error) {
      console.error("inviteToVideo error", error);
      error.response.data.message === "Not enough money to invite" ? toast.error("Не достаточно денег!") : toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
    }
  };

  const onClickHandle = (id) => {
    props.openProfileInfo();
    props.setUserOtherId(id);
  };

  return (
    <div className={styles.streamerItemWrap}>
      <div className={styles.left} onClick={() => onClickHandle(id)}>
        <img
          className={styles.imgLogo}
          src={avatar ? avatar : userAvatar ? userAvatar : avatarImg}
          alt="img"
        />
        <div className={`${online ? styles.imgOnline : styles.imgOffline} ${styles.imgStatus}`}
        ></div>
        <div className={styles.infoCards}>
          <h2>{username}</h2>
          <p className={styles.raitingNum}>
            <img style={{ marginRight: "5px" }} src={star} alt="star" />
            {avgRating && Number(avgRating[2]) > 1
              ? Number(avgRating).toFixed(1)
              : Number(avgRating).toFixed(0)}
          </p>
          <div>
            <p className={styles.subTitleText}>{truncateText(about_me, 13)}</p>
          </div>
        </div>
      </div>
        <div>
          <Button
            onClickHandle={() => inviteToVideo(id)}
            className={styles.btn}
            status={status}
          />
      </div>
    </div>
  );
};
