import MyIcon from "../myIcon/MyIcon";
import s from "./RatesUser.module.css";

const RatesUser = ({ isOtherProfile, userInfoRates }) => {
    const maxStars = 5;

    const stars = Array.from({ length: maxStars }, (_, index) => (
        <MyIcon
            key={index}
            image={index < userInfoRates ? "starFill" : "star"}
            width={window.innerWidth >= 821 ? "36px" : "22px"}
            height={window.innerWidth >= 821 ? "36px" : "22px"}
        />
    ));

    return (
        <>
            <div className={s.star}>{stars}</div>

            {!isOtherProfile && (
                <div style={{ fontWeight: '400', fontFamily: "Segoe UI" }} className={s.textRate}>
                    {`Ваш рейтинг ${userInfoRates}. Он будет повышаться от успешно проведенных стримов`}
                </div>
            )}
        </>
    );
};

export default RatesUser;
 