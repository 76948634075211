export const isLink = (str) => {
        const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return pattern.test(str);
};

export const truncateText = (text, maxLength) => {
        if (text && text.length > maxLength) {
            return text.slice(0, maxLength) + "...";
        }
        return text;
};

export const formatTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
          
        // const day = date.getDate().toString().padStart(2, '0');
        // const month = (date.getMonth() + 1).toString().padStart(2, '0');
        // const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
      
        return `${hours}:${minutes}`;
      }

export const sortedData = (data) => {
        data.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);

        return dateA - dateB;
                });
        return data;
} 

export const createUniqueArray = (arr, prop1) => {
        const seen = new Set();
        const uniqueArray = [];
        
        arr.forEach(obj => {
          const key = `${obj[prop1]}`;
          if (!seen.has(key)) {
            seen.add(key);
            uniqueArray.push(obj);
          }
        });
        
        return uniqueArray;
};

const getDayOfWeek = (date) => {
        const daysOfWeek = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
        return daysOfWeek[date.getDay()];
}

const formatDate = (date) => {
        const dayOfWeek = getDayOfWeek(date);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().substring(2);
        return `${dayOfWeek} ${day}.${month}.${year}`;
};

export const groupMessagesByDay = (messages) => {
        const groupedMessages = {};
    
        messages.forEach(message => {
          const createdAtDate = new Date(message.createdAt);
          const formattedDate = formatDate(createdAtDate);
    
          if (!groupedMessages[formattedDate]) {
            groupedMessages[formattedDate] = [];
          }
    
          groupedMessages[formattedDate].push(message);
        });
    
        return groupedMessages;
};