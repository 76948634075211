import axios from 'axios';


export const http = (type = 'api') => {

  const token = localStorage.getItem('gamer_info');
  let options;

  type === 'withoutToken' ?
    (
      options = {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${null}`,
        },
        baseURL: process.env.REACT_APP_BASE_URL,
      }
    ) :
    type === 'api' ?
      (
        options = {
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
          },
          baseURL: process.env.REACT_APP_BASE_URL,
        }
      ) :
      type === 'cors' ?
      (
        options = {
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*'
          },
          baseURL: process.env.REACT_APP_BASE_URL,
        }
      ) :
      (
        options = {
          headers: {
            // 'accept': '*/*',
            'Access-Control-Allow-Origin': '*'
          },
          baseURL: process.env.REACT_APP_BASE_URL,
        }
      );

  let instance = axios.create(options);


  instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject({ ...error }),
  );

  return instance;
};






