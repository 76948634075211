import React, { useState } from 'react';
import styles from './RaitingCall.module.css';
import cross from './img/cross.svg';
import activeStar from './img/activeStar.svg';
import inActiveStar from './img/inActiveStar.svg';
import { post_create_rating } from '../../api/video';
import { useToast } from 'rc-toastr';

export const RaitingCall = ({ setOpenRatingCall, videoChat, userMyInfo, rating = 0 }) => {
  const { toast } = useToast()
  const [activeStars, setActiveStars] = useState(Array(5).fill(false));
  const [checkedStars, setCheckedStars] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = () => {
    setOpenRatingCall(false)
  }

  const handleHover = (index) => {
    const updatedStars = activeStars.map((_, i) => i <= index);
    setActiveStars(updatedStars);
  };

  const handleClose = (e) => {
    if (e.target.classList.contains(styles.modalOverlay)) {
      onClose()
    }
  }

  const handleLeave = () => {
    const updatedStars = activeStars.map((_, i) => i < checkedStars);
    setActiveStars(updatedStars);
  };

  const handleClick = (index) => setCheckedStars(index + 1);
  const sendRaiting = async() => {
    if (isLoading) {
      return;
    }

    const req = {
      rate: checkedStars,
      raterId: userMyInfo.gamerId,
      userId: videoChat?.participants?.caller?.userId || 2,
    }

    try {
      setIsLoading(true)
      await post_create_rating(req)
      toast.success("Успешно отправленно!")
    } catch (error) {
      console.error(error)
      toast.error(error.response.data.message)
    } finally {
      setIsLoading(false)
      setOpenRatingCall(false)
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={handleClose} onMouseLeave={handleLeave}>
      <div className={styles.modalContent}>
        <div className={styles.top}>
          <span>Оцените общение с хостес</span>
          <img style={{cursor: "pointer"}} onClick={onClose} src={cross} alt='cross Img' />
        </div>
        <div className={styles.bottom}>
          <div className={styles.starsList}>
            {activeStars.map((isActive, index) => (
              <img
                key={index}
                src={isActive ? activeStar : inActiveStar}
                alt={`star ${index}`}
                onMouseEnter={() => handleHover(index)}
                onClick={() => handleClick(index)}
              />
            ))}
          </div>
          <button onClick={sendRaiting} className={styles.btn}>Оценить</button>
        </div>        
      </div>
    </div>
  );
};
