import { useEffect, useRef, useState } from 'react';

import { PublicConsumer } from '../../lib/video/public';

import { socket } from '../../socket/socket';
import ChatPublic from '../../components/chatPublic/ChatPublic';
import s from './Home.module.css';
import { get_video_active_id } from '../../api/video';
import { user_info, user_register } from '../../api/users';
import Gift from '../../components/gift/Gift';
import { WebSocketProvider } from '../../socket/webSocketConnection';

let client = new PublicConsumer();

function Home({ children, tokenProp }) {
  const videoRef = useRef<any>(null);
  const submenuRef = useRef(null);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [giftVisible, setGiftVisible] = useState(false);
  const [isStreamGo, setIsStreamGo] = useState(false);
  const [userOtherId, setUserOtherId] = useState(null);
  const [userMyId, setUserMyId] = useState(null);
  const [userMyInfo, setUserMyInfo] = useState({});
  const [publicConsumer, setPublicConsumer] = useState<PublicConsumer>(client);

  const [openChatById, setOpenChatById] = useState({
    chatId: null,
    userName: '',
    createdAt: '',
    userId: null,
  });

  useEffect(() => {
    if (tokenProp) getUserInfo();
  }, [tokenProp]);

  useEffect(() => {
    const onConnect = () => {};

    socket.on('connect', onConnect);

    return () => {
      socket.off('connect', onConnect);
    };
  }, []);

  useEffect(() => {
    const onNewProducer = (video) => {
      publicConsumer.close();
      setPublicConsumer(new PublicConsumer());

      setGiftVisible(true);
      setIsStreamGo(true);
    };

    const onProduserDisconnect = () => {
      publicConsumer.close();
      setGiftVisible(false);
      setIsStreamGo(false);
    };

    const setStreamToVideoElem = async (stream) => {
      if (!videoRef.current) return;

      videoRef.current.srcObject = await stream;
    };

    socket.on('newProducer', onNewProducer);
    socket.on('producerDisconnect', onProduserDisconnect);

    publicConsumer.events.on('stream', setStreamToVideoElem);

    // Handle start new hostes
    // socket.on('startNextProducer', () => {});

    return () => {
      socket.off('newProducer', onNewProducer);
      socket.off('producerDisconnect', onNewProducer);
      publicConsumer.events.off('stream', setStreamToVideoElem);
    };
  }, [publicConsumer]);

  useEffect(() => {
    if (isConnected) onBtnSubscribe();
  }, [isConnected, publicConsumer]);

  useEffect(() => {
    const onConnect = () => setIsConnected(true);
    const onDisconnect = () => setIsConnected(false);

    const onCheckActiveStrem = (data) => {
      if (data === null) {
        setIsStreamGo(false);
        setGiftVisible(false);
      } else {
        setIsStreamGo(data);
        setGiftVisible(data);
      }
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('producerOnline', onCheckActiveStrem);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('producerOnline', onCheckActiveStrem);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    videoStream();
  }, []);

  const closeModalGift = () => setGiftVisible(false);

  const getUserInfo = async () =>
    await new Promise((resolve) => {
      user_info()
        .then(resolve)
        .catch((err) => {
          if (err.response.data.message === 'Unauthorized')
            user_register({ token: tokenProp })
              .then(resolve)
              .catch((err) => console.error('get user info error', err));
          else console.error('get user info error', err);
        });
    }).then(({ data }: any) => {
      setUserMyId(data.data.id);
      setUserMyInfo(data.data);
    });

  const videoStream = async () => {
    try {
      const { data } = await get_video_active_id();
      data.data && setUserOtherId(data.data.scheduleTime.user.id);
      socket.emit('producerOnline');
    } catch (error) {
      if (error.response?.data.message === 'Active stream not found!') return;
      console.error('videoStream error');
    }
  };

  async function onBtnSubscribe() {
    await publicConsumer.createDevice();
    await publicConsumer.joinStream();
  }

  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setGiftVisible(false);
    }
  };

  return (
    <WebSocketProvider token={tokenProp}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <div className={`${s.video}`}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
              }}
            >
              <video
                ref={videoRef}
                autoPlay
                muted
                controls
                className={`${s.videoStream} ${
                  isStreamGo ? s.videoStreamOpen : s.videoStreamClose
                }`}
              ></video>
            </div>
            {giftVisible && (
              <Gift
                closeModalGift={closeModalGift}
                submenuRef={submenuRef}
                userOtherId={userOtherId}
                userMyInfo={userMyInfo}
              />
            )}
          </div>

          <div className={s.chatPublic}>
            <ChatPublic
              setOpenChatById={setOpenChatById}
              userMyId={userMyId}
              token={tokenProp}
            />
          </div>
        </div>

        {children}
      </div>
    </WebSocketProvider>
  );
}

export default Home;
