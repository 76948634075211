//chats
export const CHAT_LIST = "chats/list";
export const CHAT_CREATE = "chats/create";
export const CHATS_COUNT = "chats/unread/count";
export const CHATS_FILTER = "chats/list";
export const CHATS_FILTER_AVAIBLE_HOSTES = "users/hostes/list";
export const CHAT_PUBLIC = "chats/public";
export const CHAT_ID = "chats";
export const CHAT_LIST_FRIENDS = "chats/gamer/list";


//video
export const VIDEO_ACTIVE_ID = "videostream/active";
export const VIDEO_INVITE = "videochat/invite/request";
export const RATING_CREATE = "/ratings/create";

//users
export const USERS_HOSTES_LIST = "/users/hostes/list";
export const TAKE_OTHER_USER = "users/";
export const FRIEND_DEL = "friends/delete";
export const FRIEND_ADD = "friends/request";
export const TEAMS_COMPLAINTS = "/complaints/create/gamer";
export const FIND_FRIEND = "friends/find";
export const CHECK_FRIEND = "friends/check";
export const GIFT = "gifts/create";
export const USER_BALANCY = "balance";
export const USER_FRIENDS_COUNT = "friends/gamer/applications/count";
export const USER_FRIENDS_REQUEST = "friends/gamer/applications";


//user
export const USER_INFO = "users/profile";
export const USER_INFO_STATUS = "videochat/invites/sent";
export const LOGIN = "auth/login";
export const USER_REGISTER = "auth/register/gamer";




