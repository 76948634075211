import React from 'react';
import closeImg from '../../images/close.svg';
import s from './Rules.module.css';
import data from './data.json';

const Rules = ({ setOpenRules }) => {

  return (
    <div className={s.container}>
      <div className={s.header}>
        <div>Правила чата</div>
        <div className={s.close} onClick={() => setOpenRules(false)}><img src={closeImg} alt='close' /></div>
      </div>
      <div className={s.rules}>
        <div className={s.items}>
          {data.map((item, i) => (<div className={s.items} key={i}>{item.name}</div>))}
        </div>
      </div>
    </div>
  );
};

export default Rules;