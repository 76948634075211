import React, { RefObject, useEffect, useRef, useState } from 'react';

import { socket } from '../../socket/socket';
import s from './videoChat.module.css';
import checkHostes from '../../images/checkHostes.svg';
import { PrivatVideoChat } from '../privatVideoChat/PrivatVideoChat';
import { user_info, user_register } from '../../api/users';
import MainMenu from '../mainMenu/MainMenu';
import { get_count_chat } from '../../api/chats';
import BlockFindHostes from '../mainMenu/components/BlockFindHostes';
import { RaitingCall } from '../raitingCall/RaitingCall';
import { WebSocketProvider } from '../../socket/webSocketConnection';
import Draggable from 'react-draggable';
import { PrivateClient } from '../../lib/video/private';

let client = new PrivateClient();


export function VideoChat({ tokenProp }) {
  const [openMainMenu, setOpenMainMenu] = useState<boolean>(false);
  const [openVideoPrivateChat, setOpenVideoPrivateChat] =
    useState<boolean>(false);
  const [videoChat, setVideoChat] = useState({});
  const [userMyId, setUserMyId] = useState(null);
  const [userMyInfo, setUserMyInfo] = useState({});
  const [countChats, setCountChats] = useState<number>(0);
  const [isFindHostes, setIsFindHostes] = useState<boolean>(true);
  const [openRatingCall, setOpenRatingCall] = useState<boolean>(false);

  const [socketState, setSocketState] = useState<boolean>(false);

  const dragContainerRef = useRef<HTMLDivElement>(null);
  const videoDragContainerRef = useRef<HTMLDivElement>(null);
  const [dragContainerWidth, setDragContainerWidth] = useState(0);
  const [dragContainereHeight, setDragContainerHeight] = useState(0);
  const [dragContainerPositions, setDragContainerPositions] = useState({top: 0, left: 0})
  const [privateClient, setPrivateClient] = useState(client);

  const onUpdateClientInstance = () => {
    let client = new PrivateClient()
    setPrivateClient(client)
}



  const [openChatById, setOpenChatById] = useState({
    chatId: null,
    userName: '',
    createdAt: '',
    userId: null,
  });

  useEffect(() => {
    if (!tokenProp) {
      setOpenMainMenu(false);
      setOpenVideoPrivateChat(false);
    }
  }, [tokenProp]);

  useEffect(() => {
    if (tokenProp) {
      getChtsCount();
      getUserInfo();
    }
  }, [tokenProp, socketState]);

  // Private chat messages
  useEffect(() => {
    const onConnect = () => setSocketState(true);
    const onDisconnect = () => setSocketState(false);

    const onVideochat = (data: { videochat: any }) => setVideoChat(data);

    const onChatNewMessage = (message) => {
      setCountChats((prev) => prev + message.countAllUnreadMessages);
    };

    const onMessageReaded = () =>
      setCountChats((prev) => (prev > 0 ? prev - 1 : prev - 0));
    const onMessagesReaded = () => getChtsCount();

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    socket.on('chat_new_message', onChatNewMessage);
    socket.on('message_readed', onMessageReaded);
    socket.on('messages_readed', onMessagesReaded);
    socket.on('videochat', onVideochat);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);

      socket.off('chat_new_message', onChatNewMessage);
      socket.off('message_readed', onMessageReaded);
      socket.off('messages_readed', onMessagesReaded);
      socket.off('videochat', onVideochat);
    };
  }, []);

  // Private chat calls
  useEffect(() => {
    const onOffer = () => {
      setOpenVideoPrivateChat(true)
    };
    const onEnd = () => {
      privateClient.events.emit('close');
      onUpdateClientInstance()
      setOpenVideoPrivateChat(false);
      setOpenRatingCall(true);
    };

    socket.on('offer', onOffer);
    socket.on('end', onEnd);

    return () => {
      socket.off('offer', onOffer);
      socket.off('end', onEnd);
    };
  }, [privateClient]);

  const getUserInfo = async () =>
    await new Promise((resolve) => {
      user_info()
        .then(resolve)
        .catch((err) => {
          if (err.response.data.message === 'Unauthorized')
            user_register({ token: tokenProp })
              .then(resolve)
              .catch((err) => console.error('get user info error', err));
          else console.error('get user info error', err);
        });
    }).then(({ data }: any) => {
      setUserMyId(data.data.id);
      setUserMyInfo(data.data);
    });

  const getChtsCount = async () => {
    try {
      const { data } = await get_count_chat();
      setCountChats(data.data.count);
    } catch (error) {
      console.error('get_count_chat error', error);
    }
  };

  const handleToggleChat = () => {
    const timerId = setTimeout(() => {
      setOpenMainMenu(!openMainMenu);
      setIsFindHostes(!isFindHostes);
    }, 100);

    return () => clearTimeout(timerId);
  };
  useEffect(() => {
    openChatById && openChatById.chatId && setOpenMainMenu(true);
  }, [openChatById]);

  useEffect(() => {
    if (dragContainerRef.current && openMainMenu) {
      const [obj] = dragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
    if (videoDragContainerRef.current && openVideoPrivateChat) {
      const [obj] = videoDragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
  }, [openMainMenu, openVideoPrivateChat])

  useEffect(() => {
    const updateSize = () => {
      if (dragContainerRef.current) {
        const { clientWidth, clientHeight } = dragContainerRef.current;
        setDragContainerWidth(clientWidth);
        setDragContainerHeight(clientHeight);
      }
    };
    updateSize();

    const resizeObserver = new ResizeObserver(updateSize);

    if (openMainMenu && dragContainerRef.current) {
      resizeObserver.observe(dragContainerRef.current);
    }

    return () => {
      if (dragContainerRef.current) {
        resizeObserver.unobserve(dragContainerRef.current);
      }
    };
  }, [openMainMenu]);

  useEffect(() => {
    if (!openVideoPrivateChat) {
      return;
    }
    const updateSize = () => {
      if (videoDragContainerRef.current) {
        const { clientWidth, clientHeight } = videoDragContainerRef.current;
        setDragContainerWidth(clientWidth);
        setDragContainerHeight(clientHeight);
      }
    };
    updateSize();

    const resizeObserver = new ResizeObserver(updateSize);

    if (videoDragContainerRef.current) {
      resizeObserver.observe(videoDragContainerRef.current);
    }

    return () => {
      if (videoDragContainerRef.current) {
        resizeObserver.unobserve(videoDragContainerRef.current);
      }
    };
  }, [openVideoPrivateChat]);

  const onMainMenuDrag = () => {
    if (dragContainerRef?.current) {
      const [obj] = dragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
  }

  const onVideoModalDrag = () => {
    if (videoDragContainerRef?.current) {
      const [obj] = videoDragContainerRef.current.getClientRects()
      setDragContainerPositions({top: obj.top, left: obj.left})
    }
  }

  const boundsSettings = {
    left:
      window.innerWidth <= 1700
        ? -window.innerWidth + dragContainerWidth + 20
        : -window.innerWidth + dragContainerWidth + 30,
    right: window.innerWidth <= 1700 ? 30 : 30,
    bottom:
      window.innerWidth <= 1700
        ? window.innerHeight - dragContainereHeight
        : window.innerHeight - dragContainereHeight,
    top:
      window.innerWidth <= 1700
        ? -(window.innerHeight - dragContainereHeight - 85)
        : -(window.innerHeight - dragContainereHeight - 85),
  }
  return (
    <WebSocketProvider token={tokenProp}>
       {openMainMenu && !openVideoPrivateChat && (
      <Draggable
        bounds={boundsSettings}
        handle={'.handleHeader'}
        disabled={window.innerWidth <= 430}
        onDrag={onMainMenuDrag}
      >
        <div ref={dragContainerRef} className={s.videoChatWrap}>
            <div className={s.privateChat}>
              <MainMenu
                openChatById={openChatById}
                getChtsCount={getChtsCount}
                setOpenMainMenu={handleToggleChat}
                dragContainerPositions={dragContainerPositions}
              />
            </div>
        </div>
      </Draggable>
          )}
          {openVideoPrivateChat && (
      <Draggable
        bounds={boundsSettings}
        handle={'.header'}
        disabled={window.innerWidth <= 430}
        onDrag={onVideoModalDrag}
      >
        <div ref={videoDragContainerRef} className={s.videoChatWrap}>
            <div className={s.modalStream}>
              <PrivatVideoChat
                videoChat={videoChat}
                userMyId={userMyId}
                isModal
                setOpenVideoPrivateChat={setOpenVideoPrivateChat}
                setOpenMainMenu={setOpenMainMenu}
                dragContainerPositions={dragContainerPositions}
                privateClient={privateClient}
                onUpdateClientInstance={onUpdateClientInstance}
              />
            </div>
        </div>
      </Draggable>
          )}

      {openRatingCall && (
        <RaitingCall
          userMyInfo={userMyInfo}
          videoChat={videoChat}
          setOpenRatingCall={setOpenRatingCall}
        />
      )}
      {tokenProp && (
        <>
          <div className={s.checkHostes}>
            {isFindHostes && !openMainMenu && !openVideoPrivateChat && (
              <BlockFindHostes
                isFindHostes={isFindHostes}
                setIsFindHostes={setIsFindHostes}
              />
            )}
            <img
              onClick={handleToggleChat}
              src={checkHostes}
              alt="checkHostes"
            />
            {countChats !== 0 && <div className={s.count}>{countChats}</div>}
          </div>
        </>
      )}
    </WebSocketProvider>
  );
}
