import { useState } from 'react';
import { sent_gift } from '../../api/users';
import s from './ModalGift.module.css';
import arrow from './img/arrow.svg';
import { useToast } from 'rc-toastr';

export const ModalGift = ({
                            onClose,
                            userInfoData,
                            name,
                            onStremHostes,
                            handleClickHostes,
                            submenuRef,
                            closeModalGift,
                          }) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [complaintText, setComplaintText] = useState('');
  const [error, setError] = useState('');

  const handleClose = (e) => {
    if (e.target.classList.contains(s.modalOverlay)) {
      onClose();
    }
  };

  const handleTextareaChange = (e) => {
    setComplaintText(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    if (complaintText === '') {
      setError('Введите число');
      return;
    }

    try {
      setIsLoading(true);
      await sent_gift({ userId: userInfoData, hearts: Number(complaintText) });
      onStremHostes && handleClickHostes(complaintText);
      toast.success('Подарок отправлен!');
      onClose();
    } catch (error) {
      console.error('reportToUser ', error);
      toast.error('Подарок не отправлен!');
      setError(error.response.data.message);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <div className={s.modalOverlay} onClick={handleClose}>
      <div ref={submenuRef} className={s.modalContent}>
        <div className={s.top}>
          <h2>Отправить подарок</h2>
          <div onClick={() => {
            onClose();
            closeModalGift && closeModalGift();
          }}>
            <img src={arrow} alt='' />
          </div>
        </div>
        <div className={s.about}>
          <p>Укажите кол-во <span>{name}</span></p>
        </div>
        <form onSubmit={handleSubmit} className={s.form}>
          <input
            className={s.textArea}
            type='number'
            placeholder={'Укажите кол-во'}
            value={complaintText}
            onChange={(e) => {
              setError('');
              const onlyDigits = e.target.value.replace(/\D/g, '');
              handleTextareaChange(onlyDigits);
            }}
          />
          {error && <div className={s.error}>{error}</div>}
          <button className={s.btn}>Отправить</button>
        </form>
      </div>
    </div>
  );
};

