import styles from './styles.module.css';

export const ShareModal = ({ list, videoSharing, screenSharing, toggleFullScreen }) => {
  return (
    <div className={styles.wrap}>
      {list.map((item, i) => (
        <p key={i}>
          {item.text === 'Развернуть' ? (
            <div onClick={toggleFullScreen}>{item.text}</div>
          ) : (
            item.text
          )}
        </p>
      ))}
      <p onClick={() => {
        screenSharing();
      }}>
        {videoSharing ? 'Прекратить демонстрацию экрана' : 'Начать демонстрацию экрана'}
      </p>
    </div>
  );
};