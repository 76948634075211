import styles from './Button.module.css'

export const Button = ({onClickHandle, status, className, text}) => {

  return (
    <div onClick={onClickHandle} className={`${styles.btnStyles} ${status ? styles.activeBtn : ""} ${className}`}>
      {status ? 'Отправлено' : text || 'Пригласить'}

    </div>
  )
}