import { http } from './index'
import { CHECK_FRIEND, FIND_FRIEND, FRIEND_ADD, FRIEND_DEL, GIFT, LOGIN, TAKE_OTHER_USER, TEAMS_COMPLAINTS, USERS_HOSTES_LIST, USER_BALANCY, USER_FRIENDS_COUNT, USER_FRIENDS_REQUEST, USER_INFO, USER_INFO_STATUS, USER_REGISTER } from './endpoints'


export const get_users_hostes_list = async (page) => {
    return await http().get(`${USERS_HOSTES_LIST}?page=${page ? page : 1}&limit=15`)
}

export const user_info = async () => {
    return await http().get(USER_INFO)
}

export const user_balancy = async () => {
    return await http().get(USER_BALANCY)
}

export const user_friends_count = async () => {
    return await http().get(USER_FRIENDS_COUNT)
}

export const user_friends_request = async () => {
    return await http().get(USER_FRIENDS_REQUEST)
}

export const user_other = async (id) => {
    return await http().get(`${TAKE_OTHER_USER}${id}`)
}

export const user_info_status = async () => {
    return await http().get(USER_INFO_STATUS)
}

export const user_friends = async ({ userId }) => {
    return await http().post(FIND_FRIEND, { userId })
}

export const user_register = async ({ token }) => {
    return await http().post(USER_REGISTER, { token })
}

export const user_friend_check = async ({ userId }) => {
    return await http().post(CHECK_FRIEND, { userId })
}

export const friend_del = async ({ userId }) => {
    return await http().post(FRIEND_DEL, { userId })
}

export const friend_add = async ({ userId }) => {
    return await http().post(FRIEND_ADD, { userId })
}

export const sent_gift = async ({ userId, hearts }) => {
    return await http().post(GIFT, { userId, hearts })
}

export const post_create_team_complaints = async ({ userId, text }) => {
    return await http().post(TEAMS_COMPLAINTS, { userId, text })
}

export const login = async ({ email, password }) => {
    return await http("login").post(LOGIN, { email, password })
}

export default {
    get_users_hostes_list,
    user_info_status,
    user_info,
    user_other,
    login,
    friend_add,
    post_create_team_complaints,
    sent_gift,
    user_friends,
    user_register,
    user_friends_count,
}