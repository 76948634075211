import { Socket, io } from 'socket.io-client';
import environment from '../environment';

const URL = environment.SOCKET_URL;
export const socket: Socket = io(URL, {
  transports: ['websocket'],
  upgrade: false,
});

class TokenManager {
  private _initialized: boolean = false;
  public token: string = '';

  update(newToken: string) {
    if (!this._initialized) {
      this.token = newToken;
      this.sendToken();
      this._initialized = true;
    } else {
      if (newToken == this.token) return;

      this.token = newToken;
      socket.emit('leave_main_room');
      socket.emit('leave_pub_room');

      this.sendToken();
    }
  }

  sendToken() {
    localStorage.setItem('gamer_info', this.token);
    socket.emit('setAuthToken', this.token || '', () => {
      socket.emit('join_main_room');
      socket.emit('join_pub_room', 1);
    });
  }

  onConnect() {
    // Handle on reconnect
    if (this._initialized) this.sendToken();
  }
}
export const tokenManager = new TokenManager();

socket.on('connect', () => {
  tokenManager.onConnect();
});

/**
 * Update token in local storage and send new token to back
 *
 * @param token
 * @returns undefined
 */
// function updateToken(token: any) {
//   console.log('updateToken', token);
//   socket.emit('setAuthToken', token || '');

//   const currentToken = localStorage.getItem('gamer_info');
//   if (currentToken == token) return;

//   if (token) localStorage.setItem('gamer_info', token);
//   else localStorage.removeItem('gamer_info');
// }

export async function socketPromise(
  emit: string,
  data: any = {},
): Promise<any> {
  return new Promise((resolve, reject) => {
    socket.emit(emit, data, (resData: any) => {
      resolve(resData);
    });
  });
}
