import styles from './FriendRequests.module.css';
import photo from './img/img.svg';
import gold from './img/gold.svg';
import { useState } from 'react';
import { useToast } from 'rc-toastr';
import { approve_notification } from '../../api/notifications';

export const FriendRequests = ({
  name,
  action,
  id,
  isOnline,
  getFriendRequest,
  getFriendCount,
  img,
  msg,
}) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const approve = (link, id) => {
    if (isLoading) {
      return Promise.reject('Already loading');
    }

    setIsLoading(true);

    const processApproval = async () => {
      await approve_notification(link);
      getFriendRequest();
      getFriendCount();
    };

    return Promise.all([processApproval()])
      .catch((error) => {
        toast.error('Заявка не принята');
        console.error('Error in approval process:', error);
      })
      .finally(() => setIsLoading(false));
  };
  console.log(img);
  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        <div className={styles.left}>
          <img
            style={{ height: '41px', width: '41px' }}
            src={img ? img : photo}
            alt="img"
          />
          <div
            className={`${isOnline ? styles.imgOnline : styles.imgOffline} ${
              styles.imgStatus
            }`}
          ></div>
          <div className={styles.text}>
            <p className={styles.name}>{name}</p>
            {msg && <p>{msg}</p>}
          </div>
        </div>
        <div className={styles.right}>
          <p className={styles.id}>
            ID: <span>{id}</span>
          </p>
        </div>
      </div>
      <div className={styles.bottom}>
        <button
          onClick={() => approve(action.accept, id)}
          className={styles.ok}
        >
          Принять
        </button>
        <button
          onClick={() => approve(action.cancel, id)}
          className={styles.notOk}
        >
          Отклонить
        </button>
      </div>
    </div>
  );
};
