import React, { useEffect, useRef, useState } from 'react';
import s from './ChatPublic.module.css';
import { createUniqueArray, sortedData } from '../../utils/helpers';
import infoImg from '../../images/info.svg';
import send from '../../images/send.svg';

import Rules from '../rules/Rules';
import { get_chat_id, get_chat_public } from '../../api/chats';
import { ChatItem } from './chatItem/ChatItem';
import { socket } from '../../socket/socket';

const MAX_CHARACTER_LIMIT = 160;

const ChatPublic = ({ setOpenChatById, token, userMyId }) => {
  const containerRef = useRef(null);
  const submenuRef = useRef(null);

  const [messageInput, setMessageInput] = useState('');
  const [checkMessage, setCheckMessage] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);
  const [characterCount, setCharacterCount] = useState(0);
  const [publicChatId, setPublicChatId] = useState(null);
  const [publicChatItems, setPublicChatItems] = useState([]);
  const [openRules, setOpenRules] = useState(false);
  const [upScroll, setUpScroll] = useState(false);
  const [reciveScrol, setReciveScrol] = useState(true);
  const [blockSend, setBlockSend] = useState(true);

  useEffect(() => {
    if (token) {
      setBlockSend(false);
    } else {
      setBlockSend(true);
    }
  }, [token]);

  useEffect(() => {
    const onReceivePubMessage = (message) => {
      if (+publicChatId === +message.chat.id)
        setPublicChatItems((prev) => [
          ...prev,
          {
            id: message.id,
            text: message.text,
            status: message.status,
            createdAt: message.createdAt,
            updatedAt: message.updatedAt,
            sender: message.sender,
          },
        ]);

      if (containerRef.current)
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
    };

    socket.on('receive_pub_message', onReceivePubMessage);

    return () => {
      socket.off('receive_pub_message', onReceivePubMessage);
    };
  }, [publicChatId, containerRef.current, userMyId]);

  useEffect(() => {
    const onPublicChatOnline = (state) => setOnlineUsers(state);

    socket.on('public_chat_online', onPublicChatOnline);

    return () => {
      socket.off('public_chat_online', onPublicChatOnline);
    };
  }, []);

  useEffect(() => {
    if (containerRef.current)
      containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      if (containerRef.current)
        containerRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [page, pageLast, publicChatItems]);

  useEffect(() => {
    if (reciveScrol && containerRef.current)
      containerRef.current.scrollTop = containerRef.current.scrollHeight;

    if (upScroll && containerRef.current) containerRef.current.scrollTop = 443;
  }, [reciveScrol, upScroll, publicChatItems]);

  useEffect(() => {
    if (checkMessage && containerRef.current)
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
  }, [checkMessage]);

  useEffect(() => {
    if (messageInput !== '') {
      setCheckMessage(false);
      setUpScroll(false);
      setReciveScrol(false);
    }
  }, [messageInput]);

  useEffect(() => {
    getChatPublic();
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast) {
      if (container && container.scrollTop === 0) {
        setCheckMessage(false);

        if (timeoutId) clearTimeout(timeoutId);

        timer = setTimeout(() => {
          getMessageList(publicChatId, page);
        }, 300);

        setTimeoutId(timer);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) sendMessage();
  };

  const sendMessage = () => {
    setCheckMessage(true);
    setReciveScrol(true);

    if (messageInput.trim() !== '') {
      const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');

      socket.emit('send_pub_message', {
        userId: userMyId,
        chatId: publicChatId,
        message: cleanedMessageInput,
      });

      setMessageInput('');
    }
    setCharacterCount(0);
  };

  const getChatPublic = async () => {
    try {
      const { data } = await get_chat_public();
      setPublicChatId(data.data.id);
      getMessageList(data.data.id, 1);
      localStorage.setItem('public_id', data.data.id);
    } catch (error) {
      console.error('getChats error', error);
    }
  };

  const getMessageList = async (id, pageNum) => {
    const param = {
      id,
      page: pageNum ? pageNum : '1',
    };

    try {
      pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
      pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
      const { data } = await get_chat_id(param);
      const sorted = sortedData(data.data);
      setPublicChatItems((prevPublicChatItems) => [
        ...sorted,
        ...publicChatItems,
      ]);
      setPageLast(data.pagination.lastPage);
      data.pagination.nextPage
        ? setPage(data.pagination.nextPage)
        : setPage(page + 1);
    } catch (error) {
      console.error('getMessageInfo err', error);
    }
  };

  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setOpenRules(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={s.slideIn}>
      <div className={s.header}>
        <div>
          Online: <span>{onlineUsers}</span>
        </div>
        <div onClick={() => setOpenRules(!openRules)} className={s.info}>
          <img src={infoImg} alt="info" />
        </div>

        {openRules && (
          <div ref={submenuRef} className={s.rules}>
            <Rules setOpenRules={setOpenRules} />
          </div>
        )}
      </div>

      <div className={`${s.chat}`}>
        <div
          ref={containerRef}
          className={`${s.container}`}
          style={{ height: '394px' }}
        >
          {publicChatItems &&
            createUniqueArray(publicChatItems, 'id').map((item, index) => {
              return (
                <ChatItem
                  setOpenChatById={setOpenChatById}
                  key={index}
                  senderId={item.sender.id}
                  senderUserName={item.sender.username}
                  avatar={item.sender.avatar}
                  text={item.text}
                  sender={item.sender}
                  firstElem={index === 0 || index === 1}
                />
              );
            })}
        </div>
      </div>

      <div className={s.footer}>
        {blockSend ? (
          <div className={s.block}>
            Чтобы писать в чат необходимо авторизоваться
          </div>
        ) : (
          <div className={s.messageBlock}>
            <div className={s.messageInput}>
              <input
                disabled={blockSend}
                type="text"
                placeholder="Введите сообщение"
                value={messageInput}
                onChange={(e) => {
                  if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
                    setMessageInput(e.target.value);
                    setCharacterCount(e.target.value.length);
                  }
                }}
                onKeyDown={handleKeyDown}
              />

              <div className={s.send}>
                <div>{`${characterCount}/${MAX_CHARACTER_LIMIT}`}</div>
                <div onClick={sendMessage}>
                  <img src={send} alt="send" />
                </div>
              </div>
            </div>
            <div className={s.line}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPublic;
