import React, { createContext, useContext, useState, useEffect } from 'react';
import { socket, tokenManager } from './socket';

export const WebSocketProvider = ({ children, token }) => {
  const [socketState, setSocketState] = useState(false);

  useEffect(() => {
    const onConnect = () => {
      setSocketState(true);
    };

    const onDisconnect = () => {
      setSocketState(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);

  useEffect(() => {
    if (socketState) tokenManager.update(token);
  }, [token, socketState]);

  return <>{children}</>;
};
