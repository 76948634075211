import MyIcon from '../myIcon/MyIcon';
import s from './ModalUserProfileInfo.module.css';
import RatesUser from '../ratesUser/RatesUser';
import { post_invite_request } from '../../api/video';
import React, { useEffect, useState } from 'react';
import avatarImg from '../../images/ava.svg';
import { user_other } from '../../api/users';
import { useToast } from 'rc-toastr';

const ModalUserProfileInfo = ({
  onClose,
  statusInfo,
  setStatusInfo,
  userInfoData,
  userData,
  userInterestsData,
  getHostesListStatus,
  hostesListStatus,
}) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(statusInfo);
  const [userInfo, setUserInfo] = useState(userInfoData);
  const [userInterests, setUserInterests] = useState(userInterestsData);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  const inviteToVideo = async (id) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      await post_invite_request({ userId: id });
      setStatusInfo(true);
      setStatus(true);
      getHostesListStatus();
    } catch (error) {
      console.error('inviteToVideo error', error);
      error.response.data.message === 'Not enough money to invite'
        ? toast.error('Не достаточно денег!')
        : toast.error(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const requestUserData = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await user_other(userData);
      setUserInfo(data.data);
      setUserInterests(JSON.parse(data.data.interests));
      setStatus(hostesListStatus.includes(data.data.id));
    } catch (error) {
      console.error('requestUserData error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    userData && requestUserData();
  }, [userData]);

  return (
    <div className={s.containerProfile}>
      <div
        style={{ display: isVisible ? 'flex' : 'none' }}
        className={s.modalProfile}
      >
        <div className={s.header}>
          <div onClick={onClose} className={s.headerBack}>
            <MyIcon image={'back'} width={'20px'} height={'20px'} />
          </div>
          {!userInfo?.gamerId && userInfo?.busy !== 0 && (
            <div className={`${s.closeToVideo}`}>
              Хостес сейчас занята, попробуйте позже
            </div>
          )}

          {!userInfo?.gamerId && userInfo?.busy === 0 && (
            <div
              className={`${status ? s.closeToVideo : s.entryToVideo}`}
              onClick={() => inviteToVideo(userInfo.id)}
            >
              {status
                ? 'Приглашение отправлено'
                : 'Пригласить в приватный видео чат'}
            </div>
          )}
        </div>
        <>
          <div className={s.avatarRate}>
            <div className={s.avatarProfile}>
              <img
                src={userInfo?.avatar ? userInfo.avatar : avatarImg}
                alt="avatar"
              />
              <div
                className={`${userInfo?.online ? s.imgOnline : s.imgOffline} ${
                  s.imgStatus
                }`}
              ></div>
            </div>
            <div className={s.userNik}>
              <div className={s.nikename}>{userInfo?.username}</div>
              {/* <div className={s.line}></div> */}
              {/* <MyIcon image={"telegramIcon"} width={"20px"} height={"20px"} /> */}
              {/* <div className={s.social}>{userInfo.telegram}</div> */}
            </div>
            <RatesUser userInfoRates={userInfo?.rating} isOtherProfile />
          </div>

          <div className={s.about}>
            <div className={s.aboutText}>О себе</div>
            <div className={s.aboutDescription}>{userInfo?.about_me}</div>
          </div>

          <div className={s.likes}>
            <div className={s.likesText}>Интересы</div>
            <div className={s.likesBlock}>
              <div className={s.containerLikes}>
                {userInterests &&
                  Object.values(userInterests).map(
                    (like, index) =>
                      like !== '' && (
                        <div key={index} className={s.like}>
                          {like}
                        </div>
                      ),
                  )}
              </div>
            </div>
          </div>
        </>
      </div>

      {window.innerWidth < 820 && <div className={s.lineMobileFirst}></div>}

      {window.innerWidth < 820 && <div className={s.lineMobileSecond}></div>}
    </div>
  );
};

export default ModalUserProfileInfo;
