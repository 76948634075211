import React, { useEffect, useRef, useState } from 'react';
import s from './Chat.module.css';
import { Message } from '../../message/Messages';
import send from '../img/send.svg';
import voice from '../img/voice.svg';
import microphoneOff from '../img/microphone-off.svg';
import { createUniqueArray } from '../../../utils/helpers';
import { socket } from '../../../socket/socket';

const Chat = ({
  getMessageInfo,
  setPrivateChat,
  privateChat,
  chatId,
  pageLast,
  checkRequest,
  page,
  userMyId,
  setCheckMessageStatus,
  setCheckMessagesStatus,
  getChatCount,
  setReciveScrol,
  upScroll,
  setUpScroll,
  reciveScrol,
  mutedRemote,
  audioSharing,
}) => {
  const containerRef = useRef(null);
  const [messageInput, setMessageInput] = useState('');
  const [checkMessage, setCheckMessage] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const MAX_CHARACTER_LIMIT = 160;

  useEffect(() => {
    const onReceiveMessage = (message) => {
      if (Number(chatId) === Number(message.chat.id)) {
        message.sender.id !== userMyId &&
          socket.emit('read_message', {
            messageId: message.id,
            chatId,
          });

        const res = {
          id: message.id,
          text: message.text,
          status: message.status,
          createdAt: message.createdAt,
          updatedAt: message.updatedAt,
          sender: message.sender,
        };
        setPrivateChat((prev) => [...prev, res]);
      }
    };

    const onMessageReaded = (message) => {
      setCheckMessageStatus(message);
    };

    const onMessagesReaded = (message) => {
      setCheckMessagesStatus(message);
    };

    if (chatId) {
      socket.emit('join_room', chatId);

      socket.on('receive_message', onReceiveMessage);
      socket.on('message_readed', onMessageReaded);
      socket.on('messages_readed', onMessagesReaded);
    }

    return () => {
      socket.off('receive_message', onReceiveMessage);
      socket.off('message_readed', onMessageReaded);
      socket.off('messages_readed', onMessagesReaded);
    };
  }, [chatId, containerRef.current]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [page, pageLast, privateChat]);

  useEffect(() => {
    if (reciveScrol && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }

    if (upScroll && containerRef.current) {
      containerRef.current.scrollTop = 443;
    }
  }, [reciveScrol, upScroll, privateChat]);

  useEffect(() => {
    if (checkMessage && containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [checkMessage]);

  useEffect(() => {
    if (messageInput !== '') {
      setCheckMessage(false);
      setUpScroll(false);
      setReciveScrol(false);
    }
  }, [messageInput]);

  const handleScroll = () => {
    const container = containerRef.current;
    let timer = 0;

    if (page <= pageLast && !checkRequest) {
      if (container && container.scrollTop === 0) {
        setCheckMessage(false);
        timer = setTimeout(() => {
          getMessageInfo(chatId, page);
          if (timeoutId) {
            clearTimeout(timeoutId);
          }

          const id = setTimeout(() => {
            getChatCount(chatId);
          }, 1000);

          setTimeoutId(id);
        }, 300);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
    }
  };

  const sendMessage = () => {
    setCheckMessage(true);
    setReciveScrol(true);
    if (messageInput.trim() !== '') {
      const cleanedMessageInput = messageInput.trim().replace(/\s{2,}/g, ' ');
      socket.emit('send_message', {
        chatId,
        message: cleanedMessageInput,
      });
      setMessageInput('');
    }
  };

  return (
    <div className={s.messagesList}>
      <div ref={containerRef} className={s.container}>
        {privateChat &&
          createUniqueArray(privateChat, 'id').map((item, index) => (
            <Message
              text={item.text}
              isMyMessage={userMyId === item.sender?.id}
              key={index}
            />
          ))}
      </div>
      <div className={s.newMsg}>
        <input
          type="text"
          placeholder="Введите сообщение"
          value={messageInput}
          onChange={(e) => {
            if (e.target.value.length <= MAX_CHARACTER_LIMIT) {
              setMessageInput(e.target.value);
            }
          }}
          onKeyDown={handleKeyDown}
        />
        <img
          onClick={() => {
            audioSharing();
          }}
          src={mutedRemote ? microphoneOff : voice}
          alt="microphoneOff"
        />
        <p>
          {messageInput.length}/{MAX_CHARACTER_LIMIT}
        </p>
        <img onClick={sendMessage} src={send} alt="send" />
      </div>
    </div>
  );
};

export default Chat;
