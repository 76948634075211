import React, { useState, useEffect } from 'react';
import s from './ChatTab.module.css';
import Chats from './chats/Chats';
import ChatPrivate from './chatPrivate/ChatPrivate';
import { get_chat_id_private } from '../../api/chats';
import { sortedData } from '../../utils/helpers';
import { socket } from '../../socket/socket';

const ChatTab = ({
  setIsHeader,
  hostesListStatus,
  chatsItem,
  countUnreadMessage,
  setCountUnreadMessage,
  setIsOpenMiniMenu,
  getChats,
  setChatItems,
  isOpenMini,
  getHostesListStatus,
  getChtsCount,
  openChatById,
}) => {
  const [privateChat, setPrivateChat] = useState([]);
  const [userOtherId, setUserOtherId] = useState(null);
  const [chatId, setChatId] = useState(null);
  const [pageLast, setPageLast] = useState(1);
  const [page, setPage] = useState(1);
  const [privateChatRoute, setPrivateChatRoute] = useState(false);
  const [checkMessageStatus, setCheckMessageStatus] = useState({});
  const [checkMessagesStatus, setCheckMessagesStatus] = useState({});
  const [checkIsReadMyMessage, setCheckIsReadMyMessage] = useState(true);
  const [reciveScrol, setReciveScrol] = useState(true);
  const [upScroll, setUpScroll] = useState(false);

  const getMessageInfo = async (id, pageNum) => {
    const param = { id, page: pageNum ? pageNum : '1' };

    try {
      pageNum > 1 ? setUpScroll(true) : setUpScroll(false);
      pageNum > 1 ? setReciveScrol(false) : setReciveScrol(true);
      const { data } = await get_chat_id_private(param);
      const sorted = sortedData(data.data);
      setPrivateChat((prevPrivateChat) => [...sorted, ...prevPrivateChat]);
      setPageLast(data.pagination.lastPage);
      data.pagination.nextPage
        ? setPage(data.pagination.nextPage)
        : setPage(page + 1);
    } catch (error) {
      console.error('getMessageInfo err', error);
    }
  };

  useEffect(() => {
    !privateChatRoute && setPrivateChat([]);
  }, [privateChatRoute]);

  useEffect(() => {
    const onChatNewMessage = (message) => {
      const res = {
        chat_id: message.chat.id,
        lastMessage: message.text,
        messageDate: message.createdAt,
        messageStatus: message.status,
        unreadMessageCount: message.countChatUnreadMessages,
        userAvatar: message.sender.avatar,
        userId: message.sender.id,
        username: message.sender.username,
      };

      const updatedChatsItem = chatsItem.map((item) => {
        if (item.chat_id === message.chat.id) return { ...item, ...res };
        return item;
      });

      setChatItems(updatedChatsItem);

      const updatedCountUnreadMessage = countUnreadMessage.map((item) => {
        if (item.chat_id === message.chat.id) {
          return {
            ...item,
            unreadMessageCount: (
              Number(item.unreadMessageCount) +
              Number(message.countChatUnreadMessages)
            ).toString(),
          };
        }
        return item;
      });

      setCountUnreadMessage(updatedCountUnreadMessage);
    };

    socket.on('chat_new_message', onChatNewMessage);

    return () => {
      socket.off('chat_new_message', onChatNewMessage);
    };
  }, [chatsItem]);

  useEffect(() => {
    if (checkMessageStatus && privateChat) {
      const updatedPrivateChat = privateChat.map((item) => {
        if (Number(item.id) === Number(checkMessageStatus.messsageId)) {
          return {
            ...item,
            ...checkMessageStatus,
          };
        }
        return item;
      });

      setPrivateChat(updatedPrivateChat);
    }
  }, [checkMessageStatus]);

  useEffect(() => {
    if (checkMessagesStatus && privateChat) {
      const updatedPrivateChats = privateChat.map((chat) => {
        if (checkMessagesStatus.messages.includes(chat.id)) {
          return { ...chat, status: 3 };
        }
        return chat;
      });
      setPrivateChat(updatedPrivateChats);
    }
  }, [checkMessagesStatus]);

  return (
    <div className={s.container}>
      {privateChatRoute ? (
        <ChatPrivate
          setChatId={setChatId}
          setUpScroll={setUpScroll}
          reciveScrol={reciveScrol}
          setReciveScrol={setReciveScrol}
          setPrivateChat={setPrivateChat}
          chatId={chatId}
          privateChat={privateChat}
          getMessageInfo={getMessageInfo}
          pageLast={pageLast}
          page={page}
          setPrivateChatRoute={setPrivateChatRoute}
          userOtherId={userOtherId}
          hostesListStatus={hostesListStatus}
          getHostesListStatus={getHostesListStatus}
          setCheckMessageStatus={setCheckMessageStatus}
          setCheckMessagesStatus={setCheckMessagesStatus}
          upScroll={upScroll}
          setIsHeader={setIsHeader}
          getChats={getChats}
          setIsOpenMiniMenu={setIsOpenMiniMenu}
          isOpenMini={isOpenMini}
          setCheckIsReadMyMessage={setCheckIsReadMyMessage}
        />
      ) : (
        <div style={{ height: '100%' }}>
          <Chats
            openChatById={openChatById}
            getMessageInfo={getMessageInfo}
            setUserOtherId={setUserOtherId}
            chatsItem={chatsItem}
            setChatId={setChatId}
            chatId={chatId}
            setPrivateChatRoute={setPrivateChatRoute}
            countUnreadMessage={countUnreadMessage}
            setReciveScrol={setReciveScrol}
            setIsHeader={setIsHeader}
            checkIsReadMyMessage={checkIsReadMyMessage}
            getChtsCount={getChtsCount}
          />
        </div>
      )}
    </div>
  );
};

export default ChatTab;
