import React from 'react'
import styles from "./styles.module.css";
import arrow from '../imgs/arrowPink.svg'



const BlockFindHostes = ({ setIsFindHostes, isFindHostes }) => {
  return (
    <div className={styles.containerFindHostes}>
        <div className={styles.textFindHostes}>
            Выберите покровительницу для увлекательного время провождения
        </div>
        <img className={styles.imgClose} onClick={() => setIsFindHostes(false)} style={{ cursor: 'pointer' }} src={arrow} alt='arrow' />
    </div>
  )
}

export default BlockFindHostes