import { http } from './index'
import { RATING_CREATE, VIDEO_ACTIVE_ID, VIDEO_INVITE } from './endpoints'


export const get_video_active_id = async () => {
    return await http().get(VIDEO_ACTIVE_ID)
}

export const post_invite_request = async ({ userId }) => {
    return await http().post(VIDEO_INVITE, { userId })
}

export const post_create_rating = async ({ rate, raterId, userId }) => {
    return await http().post(RATING_CREATE, { rate, raterId, userId })
}


export default {
    get_video_active_id,
    post_invite_request,
    post_create_rating,
}