import React, { useEffect, useState } from 'react';
import s from './Gift.module.css';
import MyIcon from '../myIcon/MyIcon';
import { socket } from '../../socket/socket';
import { ModalGift } from '../modalGift/ModalGift';

const Gift = ({ userMyInfo, userOtherId, submenuRef, closeModalGift }) => {
  const [openModalGift, setOpenModalGift] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [showInfoGift, setShowInfoGift] = useState(false);
  const [infoGift, setInfoGift] = useState({
    username: '',
    amount: 0,
  });

  useEffect(() => {
    const onGift = (data) => {
      setInfoGift({
        username: data.username,
        amount: data.amount,
      });
      setShowInfoGift(true);

      if (timerId) {
        clearTimeout(timerId);
      }

      const newTimerId = setTimeout(() => {
        setShowInfoGift(false);
      }, 3000);

      setTimerId(newTimerId);
    };

    socket.on('gift', onGift);

    return () => {
      socket.off('gift', onGift);

      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  const handleClick = (complaintText) => {
    const req = {
      username: userMyInfo.username ? userMyInfo.username : "",
      amount: complaintText,
    };
    socket.emit('gift', req);
  };

  return (
    <div className={s.container}>
      <div className={s.gift} onClick={() => setOpenModalGift(true)}>
        <MyIcon image={'gift'} height={'25px'} width={'25px'} />
        Сделать подарок
        {openModalGift && (
          <ModalGift
            submenuRef={submenuRef}
            onClose={() => setOpenModalGift(false)}
            handleClickHostes={handleClick}
            userInfoData={userOtherId}
            onStremHostes
            closeModalGift={closeModalGift}
          />
        )}
      </div>

      {showInfoGift && (
        <div className={s.amount}>
          <div className={s.logoImg}>
            <MyIcon image={'logoGift'} height={'100px'} width={'140px'} />
          </div>
          <div className={s.count}>
            <span className={s.text}> X {infoGift?.amount}</span>
          </div>
          <div>{infoGift?.username ? infoGift.username.toLocaleUpperCase() : "Uknown user"}</div>
        </div>
      )}
    </div>
  );
};

export default Gift;
