import { CHATS_FILTER, CHATS_FILTER_AVAIBLE_HOSTES } from "./endpoints"
import {http} from './index'

export const chat_filter_and_search = async ({username}) => {
        return await http().get(`${CHATS_FILTER}?username=${username}`)
}

export const chat_filter_and_search_hostes = async ({username}) => {
        return await http().get(`${CHATS_FILTER_AVAIBLE_HOSTES}?username=${username}`)
}

export default {
        chat_filter_and_search,
        chat_filter_and_search_hostes,
}