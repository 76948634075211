import styles from './ChatRulesBig.module.css';
import left from './img/arrowLeft.svg';
import down from './img/arrowDown.svg';

export const ChatRulesBig = ({ setRulesVisible, rulesVisible }) => (
  <div className={styles.wrap}>
    <div className={styles.top}>
      <div className={styles.left}>
        <img
          onClick={() => setRulesVisible(!rulesVisible)}
          src={left}
          alt="left"
        />
        <span>Правила чата</span>
      </div>
      <div>
        <img src={down} alt="down" />
      </div>
    </div>
    <div className={styles.bottom}>
      <div className={styles.rules}>
        <h3>Как пользоваться сервисом</h3>
      </div>
      <div className={styles.rules}>
        <h3>Для чего предназначен данный сервис</h3>
        <p>
          Данная платформа предоставляет возможность каждому стать частью
          команды хостес «Mur Amur Casino» и сопровождать наших гостей во время
          их времяпровождения на сайте казино.
        </p>
        <p>
          Главная задача хостес – сделать пребывание клиентов казино на сайте
          более приятным и интересным. Мы уверены в том, что чем больше приятных
          эмоций наши клиенты получат во время общения, тем чаще они будут
          посещать казино, тем самым увеличивая его прибыль.
        </p>
      </div>
      <div className={styles.rules}>
        <p>Существует 2 основных вида развлечения гостей:</p>
        <ul>
          <li>
            <span>Главный стрим казино</span>– на главной странице{' '}
            <a href="https://muramur.com">https://muramur.com</a> хостес
            общаются с посетителями сайта, которые находятся в общем чате.
            Задача хостес – демонстрировать различные игры, представленные в
            нашем казино и общаться с аудиторией из чата. Чем больше симпатий вы
            сможете заполучить у пользователей, тем больше они будут дарить вам
            подарков, которые вы сможете конвертировать в деньги и вывести на
            свою банковскую карту.
          </li>
          <div style={{ height: '20px' }} />
          <li>
            <span>Приватное общение</span>– за каждую минуту общения с вами
            пользователи будут платить вам. Ваша задача быть приятным и
            интересным собеседником для наших гостей. Вся статистика общения
            будет автоматически фиксироваться в вашем личном кабинете. Вы можете
            выводить заработанные деньги в любой момент на свою банковскую
            карту. В приватных беседах подразумевается только дружеское общение
            на любые темы не касающихся запрещенных (политика, религия, секс).
          </li>
        </ul>
      </div>
      <div className={styles.rules}>
        <h3>Как активировать учетную запись</h3>
        <p>
          Чтобы стать частью нашей команды хостес, вам необходимо зайти в
          «Личный кабинет» и заполнить анкету. После того как все поля анкеты
          будут заполнены, ее необходимо отправить на проверку нашему
          администратору, нажав соответствующую кнопку «Запросить проверку»
        </p>
        <p>
          В личном кабинете вы также можете следить за статусом рассмотрения
          вашей анкеты. Если ваша анкета будет одобрена, статус в личном
          кабинете изменится на «Ваша анкета принята» после чего вам откроются
          дополнительные функции для управления сервисом, к примеру график
          регистрации на стримы, возможность принимать приватные беседы,
          создавать команды и участвовать во внутренних соревнованиях хостес для
          того, чтобы приумножить свой заработок на проекте.
        </p>
        <p>
          Если же по каким-то причинам ваша анкета отклонена, попробуйте
          проверить, все ли поля заполнены корректно и попробуйте ее отправить
          заново.
        </p>
        <p>
          Администрация сервиса может отказать вам в участии без обоснования
          каких-либо причин, поэтому если вам пришел отказ второй раз, не
          пытайтесь создавать анкету заново, так как вся история пользователей
          отслеживается.
        </p>
        <p>
          Так как мы очень дорожим репутацией нашего сервиса перед клиентами, мы
          не можем позволить себе принимать для работы на проекте абсолютно всех
          без исключения. Мы рассматриваем для работы только девушек с приятной
          внешностью, добрым и приятным характером, общительных, умеющих первыми
          начать беседу и способными поддержать в разговоре любую тему
        </p>
        <p>
          Администрация казино всегда стоит на защите хостес, поэтому общаясь с
          нашими клиентами, вы можете быть уверенными, что в случае
          неадекватного поведения со стороны наших клиентов, мы сделаем все
          возможное, чтобы ограничить такого клиента от возможности дальнейшего
          общения на проекте.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Как начать зарабатывать</h3>
        <p>
          Когда ваша анкета прошла проверку и вы получили статус «Ваша анкета
          принята» вы можете сразу же переходить к общению с клиентами.
          Существует несколько вариантов для заработка на проекте:
        </p>
      </div>
      <div className={styles.rules}>
        <h3>1. Проведение стримов на главной странице казино</h3>
        <p>
          Чтобы начать трансляцию на главной странице перейдите в раздел
          «Расписание» и выберите время, в которое вы готовы провести стрим.
          Если вы забронировали время для стрима, но по каким-то причинам не
          провели его, вы будете заблокированы на сервисе навсегда, поэтому
          относить к этому максимально серьезно.
        </p>
        <p>
          Во время стримов пользователи будут поощрять вас своими подарками,
          которые вы сможете моментально конвертировать в деньги и вывести на
          свою банковскую карту.
        </p>
        <p>
          Также стримы развивают вашу популярность среди гостей нашего казино,
          чем выше будет ваша популярность, тем чаще пользователи захотят
          пообщаться с вами в приватных беседах, где каждая минута вашего
          общения будет оплачиваться согласно установленной тарификации.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>2. Приватное общение</h3>
        <p>
          Чтобы ваша кандидатура появилась в списке хостес доступных для
          общения, вам нужно переключить ползунок «Статус онлайн» расположенный
          внизу основного меню в активный режим. После этого ваша анкета станет
          доступна пользователям в соответствующем меню выбора хостес на
          странице казино.
        </p>
        <p>
          Когда кто-то из пользователей захочет пообщаться с вами – вам придет
          соответствующее оповещение в разделе «Уведомлений». Как только вы
          подтвердите заявку, между вами и клиентом появиться чат для общения.
          За каждую минуту общения вам начисляются Амуры, которые вы сможете
          обменять на деньги на странице баланса и вывести на свою банковскую
          карту.
        </p>
        <p>
          После каждого общения с вами, пользователи будут ставить оценки,
          поэтому чем выше ваш рейтинг на проекте, тем выше ваша анкета будет в
          списке выдачи и тем чаще именно вас пользователи будут выбирать для
          общения.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>3. Подарки</h3>
        <p>
          Гости казино будут отправлять вам Амуры (внутриигровая валюта) как во
          время стрима на главной странице, так и во время приватных бесед. Чем
          больше будет ваша популярность и симпатии, тем больше подарков вы
          будете получать. Амуры можно конвертировать в деньги на странице
          баланса по установленному курсу и вывести на свою банковскую карту.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>4. Реферальная программа</h3>
        <p>
          Чем больше новых хостес вы пригласите на проект, тем выше будет ваш
          пассивный доход на проекте. Более детально вы можете ознакомится с
          этим видом заработка в разделе «Как работает реферальная программа»
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Правила поведения на сервисе</h3>
      </div>
      <div className={styles.rules}>
        <h3>Как общаться с клиентом</h3>
        <p>
          Тут все очень просто, во время общения с вами клиенты должны получать
          удовольствие, ваша задача быть максимально расположенными клиенту,
          уважать его интересы и быть интересным собеседником в любых вопросах.
          Коммуникабельность и обаяние – это ваши главные инструменты в работе,
          используйте их для того, чтобы нашим клиентам было с вами приятно и
          интересно проводить время.
        </p>
        <p>
          У нас запрещено говорить на 3 темы: политика, религия и секс – если
          гости казино отправят на вас жалобу и вы будете замечены в обсуждении
          подобных вопросов, то ваша анкета на сервисе будет заблокирована
          навсегда.
        </p>
        <p>
          Администрация казино всегда стоит на защите хостес, поэтому общаясь с
          нашими клиентами, вы можете быть уверенными, что в случае
          неадекватного поведения со стороны клиентов, мы сделаем все возможное,
          чтобы ограничить такого клиента от возможности дальнейшего общения на
          проекте.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Внешний вид</h3>
        <p>
          Во время общения с клиентами на сервисе, вы должны быть в здравом и
          трезвом состоянии, а ваш внешний вид должен быть умеренно сдержанным и
          приличным. Запрещается оголять интимные части тела, быть только в
          нижнем белье или в одежде слишком сексуального характера. Клиенты
          должны ценить именно общение с вами, а не только ваши внешние
          признаки.
        </p>
        <p>
          Помните, что у нас не публичный дом, а приличный игровой клуб, где вы
          являетесь стороной принимающей гостей – поэтому старайтесь максимально
          соответствовать образу администратора, крупье или бармена. Вы
          являетесь лицом нашего игрового клуба, поэтому от вас напрямую зависит
          наша репутация.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Технические требования к аппаратуре</h3>
        <p>
          Предоставляя сервис общения для наших клиентов, удостоверьтесь в том,
          что вы обладаете:
        </p>
        <ul>
          <li>качественным и бесперебойным интернетом</li>
          <li>видеокамерой способной передавать качественное изображения</li>
          <li>микрофоном способным качественно передавать ваш голос</li>
          <li>
            наушниками или колонками способными качественно слышать клиента
          </li>
        </ul>
        <p>
          Если со стороны клиентов мы будем получать жалобы технического
          характера, ваша учетная запись будет заблокирована. Наличие всей
          необходимой аппаратуры для общения является важным условием для работы
          в нашей команде.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Штрафы за нарушение правил</h3>
        <p>
          После каждого общения с вами, пользователи будут оценивать ваш
          рейтинг. Если ваш рейтинг будет ниже 3, то администрация оставляет за
          собой право заблокировать вашу учетную запись.
        </p>
        <p>
          Существует ряд нарушений за которые администрация будет блокировать
          вашу учетную запись без каких-либо предварительных уведомлений:
        </p>
        <ul>
          <li>Регистрация на главном стриме без его проведения</li>
          <li>
            Окончание стрима раньше, чем установлено (минимальное время стрима 4
            часа)
          </li>
          <li>Оказание интимных услуг клиентам</li>
          <li>Разговоры на запретные темы (политика, религия, секс)</li>
          <li>Выпрашивание – запрещены все формы попрошайничества</li>
          <li>Использование ненормативной лексики</li>
          <li>Хамское, оскорбительное или грубое поведение</li>
          <li>
            Реклама своих социальных сетей, услуг, контента не связанного с
            казино
          </li>
          <li>Предоставлять пользователям свои личные контактные данные</li>
        </ul>
      </div>
      <div className={styles.rules}>
        <h3>Жалобы</h3>
        <p>
          Во время общения с клиентами казино вы можете пожаловаться на клиента
          и прервать с ним беседу, в случае если:
        </p>
        <ul>
          <li>Звучит ненормативная лексика в ваш адрес</li>
          <li>Проявляется хамское, оскорбительное или грубое поведение</li>
          <li>Клиент настаивает на оказании интимных услуг</li>
          <li>Угрожает каким-либо способом</li>
          <li>
            Находится под наркотическим или сильным алкогольным опьянением
          </li>
          <li>Разговаривает на запрещенные темы (политика, религия, секс)</li>
        </ul>
      </div>
      <div className={styles.rules}>
        <h3>Как вести стрим на главной странице</h3>
      </div>
      <div className={styles.rules}>
        <h3>Как выйти в эфир</h3>
        <p>
          Чтобы ваш стрим отображался на главной странице, сначала перейдите на
          вкладку «Расписание» и выберите время, в которое вы желаете провести
          стрим.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Как должен быть оформлен стрим</h3>
        <p>
          Стрим на главной странице казино, обязательно должен проводиться в
          тихой и уютной обстановке.
        </p>
        <p>
          Во время стрима вы можете транслировать только игровой контент
          доступный в нашем казино и себя. Если задний фон места, где вы
          находитесь, не позволяет сделать атмосферу стрима уютной и приятной,
          используйте хромакей для того, чтобы скрыть из кадра все лишнее и
          оставить в потоке только ваш образ
        </p>
        <p>
          Приветствуются тематические образы и декорации подходящие под тематику
          «Mur Amur Casino».
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Как выбрать и запустить игру во время стрима</h3>
        <p>
          Чтобы стримить игровой контент, вам потребуется создать дополнительную
          учетную запись на стороне казино. Для этого перейдите по ссылке:{' '}
          <a href="https://muramur.com/registration">
            https://muramur.com/registration
          </a>
        </p>
        <p>
          Ваша задача во время стрима – освещать и демонстрировать различные
          игры, представленные в казино, однако вам не обязательно нон стопом в
          них играть. Ваше основное внимание должно быть направлено на общение с
          пользователями, а игра больше служит фоном для общения.
        </p>
        <p>
          Вы можете выбирать любую игру доступную в казино и запустить ее в демо
          режиме, в таком варианте вы сможете демонстрировать игру, при этом вам
          не нужно будет использовать для игры реальные средства. Помните, что
          не все игры поддерживают «Демо» режим.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Как происходит выплата</h3>
        <p>Как обменять заработанные Амуры</p>
        <p>
          Амуры (AMUR COIN) – автоматически начисляются на ваш баланс, когда вы
          получаете подарки от пользователей или за приватное общение с
          клиентами казино.
        </p>
        <p>
          Для того чтобы обменять Амуры на деньги, вам необходимо сначала
          перейти на вкладку «Баланса». В верхней части экрана будет
          отображаться ваш баланс Амуров, а также их курс обмена.
        </p>
        <p>
          Воспользуйтесь конвертером для перевода Амуров в деньги. Укажите
          количество Амуров, которое вы хотите обменять и нажмите кнопку
          «Конвертировать». После этого у вас на балансе появится сумма денег
          доступная для вывода.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Как вывести деньги с баланса</h3>
        <p>Чтобы вывести деньги перейдите на страницу «Баланс».</p>
        <p>
          В правой верхней части страницы вам необходимо указать номер вашей
          банковской карты, указать сумму вывода и нажать кнопку «Вывести
          средства».
        </p>
        <p>
          После того как ваша заявка на вывод будет проверена администратором,
          деньги автоматически поступят на вашу банковскую карту.
        </p>
        <p>Время вывода средств с 11:00 до 24:00 составляет не более 1 часа.</p>
      </div>
      <div className={styles.rules}>
        <h3>Как работает реферальная программа</h3>
      </div>
      <div className={styles.rules}>
        <h3>Приводи друзей и получай % от их прибыли</h3>
        <p>
          Всем участницам команды хостес доступа возможность приглашать новых
          участниц проекта с помощью персональной реферальной ссылки или кода.
        </p>
        <p>
          Чтобы поделится своей ссылкой или кодом, перейдите на страницу
          «Реферальная команда» и скопируйте соответствующую информацию. После
          этого отправьте её своему рефералу. Как только ваш реферал
          зарегистрируется, пройдет проверку и начнет зарабатывать Амуры, вы
          будете регулярно получать 10% от суммы заработанных Амуров вашим
          рефералом на свой баланс.
        </p>
        <p>
          Количество рефералов, которых вы можете пригласить – неограниченно.
          Чем больше у вас будет рефералов, тем выше будет ваш пассивный доход
          на проекте.
        </p>
      </div>
      <div className={styles.rules}>
        <h3>Ежемесячные командные соревнования</h3>
        <p>
          Хостес могут объединятся в команды для того, чтобы участвовать в
          ежемесячных соревнованиях между командами. Вы можете вступать в другие
          команды или же создать свою собственную. Более детально с интерфейсом
          по управлению командой вы можете ознакомится в разделе «Реферальная
          команда».
        </p>
        <p>
          Первые 3 команды хостес заработавшие больше всего Амуров за месяц
          получают возможность приумножить свои заработанные коины.
        </p>
        <p>Умножение Амуров происходит по такому распределению:</p>
        <ul>
          <li>За 1 место х2</li>
          <li>За 2 место х1,5</li>
          <li>За 3 место х1,2</li>
        </ul>
      </div>
      <div className={styles.rules}>
        <h3>Лидер команды</h3>
        <p>
          Если вы чувствуете в себе силы для того, чтобы собрать и организовать
          работу команды, вам необходимо перейти в раздел «Реферальная команда»,
          придумать название и нажать кнопку «Создать команду».
        </p>
        <p>
          После этого ваша команда появится в общем списке хостес. Вы можете
          рассылать приглашения в команду или же принимать заявки от участниц,
          которые найдут вашу команду в общем списке команд.
        </p>
        <p>
          Каждая команда может состоять не более чем из 10 хостес. Ваша задача
          следить за тем, чтобы участницы вашей команды имели хороший рейтинг и
          не имели жалоб от клиентов. Следите за своей командой и
          администрируйте их работу на сервисе.
        </p>
        <p>
          Все Амуры заработанные вашей командой за месяц, будут отображаться на
          балансе команды и в общем рейтинге команд. Чем больше Амуров
          заработает ваша команда, тем выше ваши шансы на победу в ежемесячных
          соревнованиях.
        </p>
        <p>
          Лидер команды также получает дополнительно на свой баланс 5% от суммы
          заработанных Амуров командой на постоянной основе. Чем сильнее будет
          ваша команда, тем больше Амуров вы будете получать на свой личный
          баланс.
        </p>
      </div>
    </div>
  </div>
);
