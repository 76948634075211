import { useState } from 'react';
import { post_create_team_complaints } from '../../api/users'
import s from './ModalComplaining.module.css'
import arrow from './img/arrow.svg'
import { useToast } from 'rc-toastr';

export const ModalComplaining = ({ onClose, userInfoData, name }) => {
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [complaintText, setComplaintText] = useState('');

  const handleClose = (e) => {
    if (e.target.classList.contains(s.modalOverlay)) {
      onClose()
    }
  }
  const handleTextareaChange = (e) => setComplaintText(e.target.value);

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(isLoading) {
      return;
    }
    try {
      setIsLoading(true)
      await post_create_team_complaints({ userId: userInfoData.id, text: complaintText})
      toast.success("Жалоба отправленна успешно!")
      onClose()
    } catch (error) {
      console.error("reportToUser ", error)
      toast.error("Жалоба не отправлена!")
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <div className={s.modalOverlay} onClick={handleClose}>
      <div className={s.modalContent}>
        <div className={s.top}>
          <h2>Пожаловаться</h2>
          <img onClick={onClose} src={arrow} alt='' />
        </div>
        <div className={s.about}>
          <p>Укажите причину жалобы на хостес <span>{name}</span></p>
        </div>
        <form onSubmit={handleSubmit} className={s.form}>
          <textarea 
            className={s.textArea} 
            placeholder={'Опишите свою жалобу'} 
            value={complaintText}
            onChange={handleTextareaChange}
          />
          <button className={s.btn}>Пожаловаться</button>
        </form>
      </div>
    </div>
  )
}

