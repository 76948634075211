import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import "rc-toastr/dist/index.css"

import './index.css';
import App from './App';
import { ToastProvider } from 'rc-toastr';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
      <ToastProvider config={{
        position: "bottom-right",
        // autoClose: 1000,
        // duration: 150,
        showIcon: false
      }} >
            <App />                   
      </ToastProvider>
  </Router>

);

