import {http} from './index'
import { CHATS_COUNT, CHATS_FILTER, CHAT_CREATE, CHAT_ID, CHAT_LIST, CHAT_LIST_FRIENDS, CHAT_PUBLIC } from './endpoints'

export const get_chat_public = async () => {
    return await http().get(CHAT_PUBLIC)
}

export const get_chat_list = async () => {
    return await http().get(`${CHAT_LIST}`)
    // return await http().get(`${CHAT_LIST}?filter=all-friends`)
}

export const get_chat_id = async ({id, page}) => {
    return await http("withoutToken").get(`${CHAT_PUBLIC}/${id}?page=${page}&limit=30`)
}

export const get_chat_id_private = async ({id, page}) => {
    return await http().get(`${CHAT_ID}/${id}?page=${page}&limit=30`)
}

export const post_create_chat = async ({ userId }) => {
    return await http().post(CHAT_CREATE, { userId })
}

export const get_count_unread_message_for_chat_id = async (chatId) => {
    return await http().get(`${CHATS_COUNT}?chatId=${chatId}`)
}

export const get_chat_list_friends = async () => {
    return await http().get(`${CHAT_LIST_FRIENDS}`)
    // return await http().get(`${CHAT_LIST}?filter=all-friends`)
}

export const get_count_chat = async () => {
    return await http().get(CHATS_COUNT)
}


export const chat_filter_and_search = async ({filter, username}) => {
    if (filter && !username) {
        return await http().get(`${CHATS_FILTER}?filter=${filter}`)
    } else if (!filter && username) {
        return await http().get(`${CHATS_FILTER}?username=${username}`)
    } else if (filter && username) {
        return await http().get(`${CHATS_FILTER}?filter=${filter}&username=${username}`)
    } else if (!filter && !username) {
        return await http().get(`${CHATS_FILTER}`)
    } else return "Have not filter or username"
}

export default {
   get_chat_list,
   get_chat_id,
   get_count_unread_message_for_chat_id,
   chat_filter_and_search,
   post_create_chat,
   get_chat_public,
   get_count_chat,
}