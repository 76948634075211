import { useEffect, useState } from 'react';
import cross from './img/cross.svg';
import s from './SubMenu.module.css';
import { friend_add, friend_del, user_friend_check, user_other } from '../../api/users';
import { post_create_chat } from '../../api/chats';

export const SubMenu = (props) => {
  const {
    userId,
    setModalComplainingOpen,
    openProfileInfo,
    setIsOpenMiniMenu,
    isPublic,
    userName,
    setUserAdmin,
    userAdmin,
    setIsFriend,
    isFriend,
    isPrivate,
    submenuRef,
    setOpenChatById,
    firstElem,
    modalStyles,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [checkFriend, setCheckFriend] = useState(false);
  const [checkGamer, setCheckGamer] = useState(false);
  const isOpen = () => setModalComplainingOpen(true);

  const getFriends = async () => {
    try {
      const { data } = await user_friend_check({ userId });
      isPrivate ? setIsFriend(data.data.status) : setCheckFriend(data.data.status);
    } catch (error) {
      console.error('error tafe friends', error);
    }
  };

  const requestUserData = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await user_other(userId);
      !isPrivate && setUserAdmin(data.data?.roles?.filter((item) => item.name === 'admin'));
      data.data && data.data.gamerId && setCheckGamer(data.data && data.data.gamerId);
    } catch (error) {
      console.error('requestUserData error', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFriends();
    requestUserData();
  }, [userId]);

  const deleteFriend = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await friend_del({ userId });
      isPrivate ? setIsFriend(0) : setCheckFriend(0);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const addToFriend = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await friend_add({ userId });
      isPrivate ? setIsFriend(2) : setCheckFriend(2);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createChat = async () => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const { data } = await post_create_chat({ userId });
      setOpenChatById({
        chatId: data.data.id,
        userName,
        createdAt: data.data.createdAt,
        userId: userId,
      });
    } catch (error) {
      console.error('createChat error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (userAdmin && userAdmin.length === 0) || isPrivate ? (userId &&
    <div
      ref={submenuRef}
      style={{
        display: isOpen ? 'block' : 'none',
        top: modalStyles ? ('40px') : (firstElem ? '17px' : '-110px'),
      }}
      className={`${s.modalOverlay}`}
    >
      <div className={s.modalContent}>
        {isPublic && (
          <div>
            <div className={s.username}>{userName}</div>
            <div className={s.idNum}>
              ID: <span>{userId}</span>
            </div>
            <div className={s.line}></div>
          </div>
        )}
        <img
          onClick={() => {
            setIsOpenMiniMenu(false);
          }}
          src={cross}
          alt='cross'
        />

        {isPrivate ? (
          (isFriend === 0 && <p onClick={addToFriend}>Добавить в друзья</p>) ||
          (isFriend === 1 && <p onClick={deleteFriend}>Удалить из друзей</p>) ||
          (isFriend === 2 && <p>Ожидает подтверждение</p>)

        ) : (
          (checkFriend === 0 && <p onClick={addToFriend}>Добавить в друзья</p>) ||
          (checkFriend === 1 && <p onClick={deleteFriend}>Удалить из друзей</p>) ||
          (checkFriend === 2 && <p>Ожидает подтверждение</p>)
        )}

        {isPublic && checkFriend === 1 && checkGamer && userAdmin.length === 0 && (
          <p onClick={createChat}>Отправить сообщение</p>)}
        {!isPublic && <p onClick={openProfileInfo}>Посмотреть профиль</p>}
        <p onClick={isOpen}>Пожаловаться</p>
      </div>
    </div>
  ) : null;
};
