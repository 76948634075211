import React, { useEffect } from 'react'
import s from "./Chats.module.css"
import { formatTime, truncateText } from '../../../utils/helpers'
import avaImg from "../../../images/ava.svg"
import MyIcon from '../../myIcon/MyIcon';

const Chats = ({ 
    setPrivateChatRoute, 
    chatId, 
    getMessageInfo, 
    setChatId, 
    chatsItem, 
    setReciveScrol,
    setIsHeader,
    setUserOtherId,
    countUnreadMessage,
    openChatById,
  }) => {

  const handelClick = (item) => {
    setReciveScrol(true) 
    if (Number(chatId) !== item.chat_id) {
      setIsHeader(false)
      setUserOtherId(item.userId)
      getMessageInfo(item?.chat_id, 1)
      setChatId(item.chat_id.toString())
      setPrivateChatRoute(true) 
    }
  }

  const openHandelClick = (item) => {
      setReciveScrol(true) 
      setIsHeader(false)
      setUserOtherId(item.userId)
      getMessageInfo(item.chatId, 1)
      setChatId(item.chatId.toString())
      setPrivateChatRoute(true) 
  }

  useEffect(() => {
    openChatById && openChatById.chatId && openHandelClick(openChatById)
  }, [openChatById])

  return (
    <div className={s.container}>
        {Array.isArray(chatsItem) && (
          chatsItem.map((item, index) => {
            return (
            <div 
              key={index} 
              onClick={() => handelClick(item)} 
              className={s.streamerItemWrap}
            >
                <div className={s.left}>
                  <img className={s.imgLogo} src={item.userAvatar ? item.userAvatar : avaImg} alt='img' />
                  <div className={`${item.online ? s.imgOnline : s.imgOffline} ${s.imgStatus}`}></div>
                  <div className={s.infoCards}>
                    <h2>{item.username ? item.username : "-"}</h2>
                    <div className={s.text}>
                      <p className={s.subTitleText}>{truncateText(item.lastMessage, 18)}</p>
                    </div>
                  </div>
                </div>
                <div className={s.timeAndUnRead}>
                  <div className={s.time}>
                      {item.messageStatus === 3  &&
                        <MyIcon image={"readMessages"} width={"20px"} height={"20px"} />
                      }
                      {item.messageStatus === 2  &&
                        <MyIcon image={"unReadMessages"} width={"20px"} height={"20px"} />
                      }
                      {item.messageDate && (
                        <div className={s.time}>
                          {formatTime(item.messageDate)}
                        </div>
                      )}
                  </div>
                    {countUnreadMessage.map((itemCount, ind) => (
                      itemCount.chat_id === item.chat_id && Number(itemCount.unreadMessageCount) > 0 && <div key={ind} className={s.count}>{itemCount.unreadMessageCount}</div>
                    ))}
                </div>
            </div>            
          )})
        )}
    </div>
  )
}

export default Chats