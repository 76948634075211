import styles from './Searcher.module.css';
import arrow from './img/arrow.svg';
import { useState } from 'react';
import search from './img/search.svg';

export const Searcher = ({
  title,
  isActive,
  setOpenMainMenu,
  getHostesListStatus,
  setIsActive,
  filterRequest,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [timeoutId, setTimeoutId] = useState(null);
  const [searchTermError, setSearchTermError] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (e === '') {
      setSearchTermError('');
      filterRequest(e);
      getHostesListStatus();
    }

    if (e.length >= 2 || e === '') {
      setSearchTermError('');
      const newTimeoutId = setTimeout(() => {
        filterRequest(e);
        getHostesListStatus();
      }, 500);

      setTimeoutId(newTimeoutId);
    } else {
      setSearchTermError('Введите больше символов');
    }
  };

  const clickHandler = (id) => setIsActive(id);

  return (
    <div className={styles.searcherWrap}>
      <div className={`${styles.title} handleHeader`}>
        <h2>{title}</h2>
        <img
          onClick={() => setOpenMainMenu(false)}
          style={{ cursor: 'pointer' }}
          src={arrow}
          alt="arrow"
        />
      </div>
      <div className={styles.switcher}>
        <p
          className={isActive === 0 ? styles.active : ''}
          onClick={() => clickHandler(0)}
        >
          Хостес
        </p>
        <div className={styles.verticalLine}></div>
        <p
          className={isActive === 1 ? styles.active : ''}
          onClick={() => clickHandler(1)}
        >
          Друзья
        </p>
      </div>
      <div className={styles.form}>
        <input
          value={inputValue}
          onChange={(e) => handleInputChange(e.target.value)}
          placeholder={'Поиск по имени'}
          className={styles.input}
          type="text"
        />
        <img className={styles.img} src={search} alt="search" />
      </div>
      {searchTermError && <div style={{ color: 'red' }}>{searchTermError}</div>}
    </div>
  );
};
