import img from './img/newFriends.svg'
import arrow from './img/arrow.svg'
import styles from './NewFriends.module.css'

function generateFriendRequestString(count) {
    if (count % 10 === 1 && count % 100 !== 11) {
        return `У вас ${count} заявка в друзья`
    } else if ((count % 10 >= 2 && count % 10 <= 4) && (count % 100 < 10 || count % 100 >= 20)) {
        return `У вас ${count} заявки в друзья`
    } else {
        return `У вас ${count} заявок в друзья`
    }
}

export const NewFriends = ({count, setOpenFriendRequest}) => {

    return (count &&
    <div className={styles.container} onClick={() => setOpenFriendRequest(true)}>
        <div className={styles.wrap}>
            <p className={styles.count}>
                <img src={img} alt="img"/>
                <span>{generateFriendRequestString(count)}</span>
            </p>
            <p className={styles.text}>
                <span>Посмотреть</span>
                <img src={arrow} alt="arrow"/>
            </p>
        </div>        
    </div>
    )
}