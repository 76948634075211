import React, { useEffect, useRef, useState } from 'react';

import s from './PrivateStream.module.css';

import { socket } from '../../socket/socket';

function PrivateStreamPage(props: any) {
  const {
    isModal,
    fullScreen,
    setOpenVideoPrivateChat,
    setOpenMainMenu,
    onUpdateClientInstance, 
    privateClient,
    setFullScreen,
    remoteVideoRef,
    toggleFullScreen
  } = props;
  const localVideoRef = useRef<any>(null);
  
  const videoContainerRef = useRef<any>(null);

  const [isConnected, setIsConnected] = useState(socket.connected);

  const [selfSockId, setSelfSockId] = useState<string>('');

  const [activeSockets, setActiveSockets] = useState<any[]>([]);

  useEffect(() => {
    if (fullScreen && videoContainerRef.current) {
      enterFullscreen(videoContainerRef.current);
    }
  }, [fullScreen]);

  const enterFullscreen = (element: any) => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  useEffect(() => {
    privateClient.events.on('local-stream', async (stream) => {
      if (localVideoRef.current) {
        localVideoRef.current.srcObject = await stream;
      }
    });

    privateClient.events.on('remote-stream', async (stream) => {
      if (remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = await stream;
      }
    });

    return () => {
    };
  }, [privateClient, localVideoRef.current, remoteVideoRef.current]);

  useEffect(() => {
    const onUpdateHostesRooms = (list: any[]) => setActiveSockets(list);
    socket.on('updateActiveSockets', onUpdateHostesRooms);
    return () => {
      socket.off('updateActiveSockets', onUpdateHostesRooms);
    };
  }, [activeSockets]);

  useEffect(() => {
    const onConnect = () => {
      setSelfSockId(socket.id);
      setIsConnected(true);
    };
    const onDisconnect = () => {
      setIsConnected(false)
      onEndCall()
      setOpenVideoPrivateChat(false)
      setOpenMainMenu(false)
    };
    const onEndCall = () => {
      privateClient.events.emit('close');
      onUpdateClientInstance()
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    // socket.on('end', onEndCall);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('end', onEndCall);
    };
  }, [privateClient]);

  return (
    <div className={s.container} ref={videoContainerRef}>
      <div className={s.wrapper}>
        <div style={{ display: 'flex', height: '100%', width: '100%', justifyContent:'center' }}>
          <div className={s.video}>
            {/* Local video */}
            <video
              ref={localVideoRef}
              autoPlay
              muted
              style={{
                width: isModal ? '0%' : '50%',
                height: '250px',
                background: 'grey',
              }}
            ></video>

            {/* Remote video */}
            <video
              ref={remoteVideoRef}
              autoPlay
              controls
              style={{
                width: isModal || fullScreen ? '100%' : '50%',
                height: fullScreen ? '100%' : '250px',
                maxWidth: isModal || fullScreen ? '100%' : '50%',
                maxHeight: fullScreen ? '100%' : '250px',
              }}
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivateStreamPage;
